import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const UndercoatContext = createContext();

const StepperProvider = ({ children }) => {
  const [selectedColor, setSelectedColor] = useState();

  return (
    <UndercoatContext.Provider value={{ selectedColor, setSelectedColor }}>
      {children}
    </UndercoatContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.any,
};

export default StepperProvider;
