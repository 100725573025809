import React from "react";
import PropTypes from "prop-types";

const MiniCanvas = ({
  miniCanvasRefs,
  dimensions,
  getCurrentStep,
  getTapeWidth,
  scale,
}) => {
  const calcDimensionWidth = (dimensions.width - 2 * getTapeWidth()) / 3;
  const calcDimensionHeight = (dimensions.height - 2 * getTapeWidth()) / 3;

  return (
    <div>
      {miniCanvasRefs.current.map((canvas, i) => (
        <canvas
          key={i}
          ref={canvas}
          width={calcDimensionWidth}
          height={calcDimensionHeight}
          style={{
            position: "absolute",
            opacity: 1,
            top:
              (calcDimensionHeight / scale + getTapeWidth() / scale) *
              Math.floor(i / 3),
            left:
              (calcDimensionWidth / scale + getTapeWidth() / scale) *
              Math.floor(i % 3),
            width: calcDimensionWidth / scale + "px",
            height: calcDimensionHeight / scale + "px",
          }}
        />
      ))}
    </div>
  );
};

MiniCanvas.propTypes = {
  miniCanvasRefs: PropTypes.any,
  dimensions: PropTypes.object,
  scale: PropTypes.number,
  getCurrentStep: PropTypes.number,
  getTapeWidth: PropTypes.func,
};

export default MiniCanvas;
