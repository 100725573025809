import React, { useMemo, useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CtaBtn from "../../Components/Btn/CtaBtn";
import nextDarkIcon from "../../icons/cta-arrowDark-icon.svg";
import style from "./Video.module.scss";
import { Link } from "react-router-dom";
import { LocalisationContext } from "../../Context/LocalisationProvider";
import videoBergmanEng from "../../videos/Video_Bergman_V1_ANGLAIS_COURTE.mp4";
import videoBergmanFr from "../../videos/Video_Bergman_V1_COURTE.mp4";
import videoBergmanFullFr from "../../videos/Video_intro_BERGMAN_V5_FRANCAIS_LIGHT.mp4";
import videoBergmanFullEn from "../../videos/Video_ intro_BERGMAN_V3_ANGLAIS_LIGHT.mp4";
import preloadFr from "../../videos/preloadFr.png";

const PLAYING_DEBOUNCE_TIME = 10;
const WAITING_DEBOUNCE_TIME = 200;
const width = window.innerWidth;

const Video = () => {
  const { langageJson, selectedLangage } = useContext(LocalisationContext);
  const navigate = useNavigate();
  const videoElementRef = useRef(null);
  const display = process.env.REACT_APP_DISPLAY;

  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const isWaitingTimeout = useRef(null);
  const isPlayingTimeout = useRef(null);

  useEffect(() => {
    if (!videoElementRef.current) {
      return;
    }

    const waitingHandler = () => {
      clearTimeout(isWaitingTimeout.current);

      isWaitingTimeout.current = setTimeout(() => {
        setIsWaiting(true);
      }, WAITING_DEBOUNCE_TIME);
    };

    const playHandler = () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsPlaying(true);
        setIsWaiting(false);
      }, PLAYING_DEBOUNCE_TIME);
    };

    const pauseHandler = () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsPlaying(false);
        setIsWaiting(false);
      }, PLAYING_DEBOUNCE_TIME);
    };

    const element = videoElementRef.current;

    element.addEventListener("waiting", waitingHandler);
    element.addEventListener("play", playHandler);
    element.addEventListener("playing", playHandler);
    element.addEventListener("pause", pauseHandler);

    // clean up
    return () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      element.removeEventListener("waiting", waitingHandler);
      element.removeEventListener("play", playHandler);
      element.removeEventListener("playing", playHandler);
      element.removeEventListener("pause", pauseHandler);
    };
  }, [videoElementRef]);

  useEffect(() => {
    if (videoElementRef.current) {
      videoElementRef.current.addEventListener("ended", function () {
        if (width <= 992) {
          navigate("/mobileSocials");
        } else {
          navigate("/intro");
        }
      });
    }
  }, [videoElementRef]);

  const displayCta = useMemo(() => {
    if (width > 992) {
      return (
        <Link
          to={width <= 992 ? "/mobileSocials" : "/intro"}
          className={`${style.videoCta}`}
        >
          <CtaBtn
            isActive={true}
            label={
              selectedLangage === "fr" ? "Passer la vidéo" : "Skip the video"
            }
            iconPosition={"end"}
            iconLight={nextDarkIcon}
            iconDark={nextDarkIcon}
          />
        </Link>
      );
    }
  }, [langageJson]);

  return (
    <div className={style.videoContainer}>
      <video
        className={style.videoPlayer}
        poster={
          process.env.REACT_APP_DISPLAY != "touchpad" ? `${preloadFr}` : ""
        }
        controls={process.env.REACT_APP_DISPLAY != "touchpad" ? true : false}
        autoPlay
        muted
        // waiting={displaySpinner()}
        preload="auto"
        ref={videoElementRef}
      >
        <source
          src={
            display === "touchpad"
              ? selectedLangage === "fr"
                ? videoBergmanFr
                : videoBergmanEng
              : selectedLangage === "fr"
              ? videoBergmanFullFr
              : videoBergmanFullEn
          }
          type="video/mp4"
          style={{ borderRadius: "0" }}
        />
      </video>
      {displayCta}
    </div>
  );
};

Video.propTypes = {};

export default Video;
