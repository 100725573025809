import React from "react";
import PropTypes from "prop-types";

import styles from "./BasicBtn.module.scss";
import BasicImage from "../BasicImage";

const BasicBtn = (props) => {
  const {
    children,
    onClick,
    showIcon = true,
    revertedColors = false,
    srcIcon = "",
    iconRight = true,
    iconSize = 16,
    ...linkProps
  } = props;

  linkProps.iconRight = iconRight;

  return (
    <>
      {iconRight ? (
        <button
          onClick={onClick}
          className={`${styles.btn} ${linkProps?.className || ""} ${
            revertedColors ? styles.reverted : ""
          }`}
          type="button"
        >
          {children}
          {showIcon && (
            <BasicImage
              src={srcIcon}
              alt=""
              className={`${styles.img} ${styles.isRight}`}
            />
          )}
        </button>
      ) : (
        <button
          onClick={onClick}
          className={`${styles.btn} ${linkProps?.className || ""} ${
            revertedColors ? styles.reverted : ""
          }`}
          type="button"
        >
          {showIcon && (
            <BasicImage
              src={srcIcon}
              alt=""
              className={`${styles.img} ${
                children ? styles.isLeft : styles.iconOnly
              }`}
              style={{ width: iconSize, height: "auto" }}
            />
          )}
          {children}
        </button>
      )}
    </>
  );
};

export default BasicBtn;

BasicBtn.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  srcIcon: PropTypes.string,
  showIcon: PropTypes.bool,
  revertedColors: PropTypes.bool,
  iconRight: PropTypes.bool,
  iconSize: PropTypes.number,
};
