import React, { useMemo, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import style from "./Modal.module.scss";
import CircularBtn from "Components/Btn/CircularBtn";
import { Container, Row, Col } from "react-bootstrap";
import { ModalContext } from "../../Context/ModalProvider";

import logoInconito from "../../pictures/inconito-logo.svg";
import logoFondationHartmanBergman from "../../pictures/fondation-hartung-bergman-logo.svg";
import logoParisMusee from "../../pictures/paris-musee-logo-dark.svg";
import logoMuseeArtModerne from "../../pictures/mam-logo-dark.svg";

import closeIcon from "../../icons/close-icon.svg";
const Index = (props) => {
  const { onValid, handleShow, handleClose, showModal } = props;
  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));
  const modalContext = useContext(ModalContext);

  useEffect(() => {
    modalContext.setModalIsOpen(showModal ? true : false);
  }, [showModal]);

  const displayModal = useMemo(() => {
    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
        dialogClassName={style.popinModalContainer}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col className={style.popinModalCloseBtnContainer} sm={12}>
                <CircularBtn
                  iconDark={closeIcon}
                  iconLight={closeIcon}
                  className={style.popinModalCloseBtn}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Col>
            </Row>
            <Row className={style.creditModalContainer}>
              <Col sm={12}>
                <h2>{selectedLangage === "fr" ? "Crédits" : "Credits"}</h2>
                <h3>
                  Ce jeu a été réalisé dans le cadre de l’exposition Anna-Eva
                  Bergman, Voyage vers l’intérieur, présentée au Musée d’Art
                  Moderne de Paris du 31 mars au 16 juillet 2023.
                </h3>
              </Col>
              <Col
                className={style.creditModalContributionContainerLeftContainer}
                sm={12}
                lg={6}
              >
                <img
                  className={style.creditModalPicture}
                  src={logoMuseeArtModerne}
                  alt="logo Paris musée"
                />
                {/* <h4 className={style.creditModalContributionLegend}>
                  mam.paris.fr
                </h4> */}
              </Col>
              <Col
                className={style.creditModalContributionContainerRightContainer}
                sm={12}
                lg={6}
              >
                <h3>
                  <span className={style.creditModalTitle}>Directeur : </span>
                  Fabrice Hergott <br />
                  <span className={style.creditModalTitle}>
                    {" "}
                    Secrétaire Générale :{" "}
                  </span>
                  Laurie Szulc <br />
                  <span className={style.creditModalTitle}>
                    Commissaire de l’exposition :
                  </span>
                  Hélène Leroy
                  <br />
                  <span className={style.creditModalTitle}>
                    {" "}
                    Assistante d’exposition :{" "}
                  </span>
                  Alexandra Jouanneau
                  <br />
                  <span className={style.creditModalTitle}>
                    Service culturel et pédagogique :{" "}
                  </span>
                  Annabelle Constant et Mathilde Frotiée
                  <br />
                  <span className={style.creditModalTitle}>
                    Service audiovisuel :{" "}
                  </span>
                  Jean-Marie Albet et Philippe Sassot
                </h3>
              </Col>
              <Col
                className={style.creditModalContributionContainerLeftContainer}
                sm={12}
                lg={6}
              >
                <img
                  className={style.creditModalSquarePicture}
                  src={logoParisMusee}
                  alt="logo Paris musée"
                />
                {/* <h4 className={style.creditModalContributionLegend}>
                  parismusees.paris.fr
                </h4> */}
              </Col>
              <Col
                className={style.creditModalContributionContainerRightContainer}
                sm={12}
                lg={6}
              >
                <h3>
                  <span className={style.creditModalTitle}>
                    Directrice générale :{" "}
                  </span>
                  Anne-Sophie de Gasquet <br />
                  <span className={style.creditModalTitle}>
                    Directrice du développement des publics,
                    <br /> des partenariats <br /> et de la communication :{" "}
                  </span>
                  Agnès Benayer
                  <br />
                  <span className={style.creditModalTitle}>
                    Chef du service numérique :{" "}
                  </span>
                  Scarlett Greco <br />
                  <span className={style.creditModalTitle}>
                    Responsable médiation <br />
                    et projet numériques :{" "}
                  </span>
                  Camille Autran
                </h3>
              </Col>
              <Col
                className={style.creditModalContributionContainerLeftContainer}
                sm={12}
                lg={6}
              >
                <img
                  className={style.creditModalSquarePicture}
                  src={logoFondationHartmanBergman}
                  alt="logo fondation Hartman Bergman"
                />
                {/* <h4 className={style.creditModalContributionLegend}>
                  fondationhartungbergman.fr
                </h4> */}
              </Col>
              <Col
                className={style.creditModalContributionContainerRightContainer}
                sm={12}
                lg={6}
              >
                <h3>
                  <span className={style.creditModalTitle}> Directeur : </span>
                  Thomas Schlesser <br />
                  <span className={style.creditModalTitle}>
                    Conservation :{" "}
                  </span>
                  Juliette Persilier
                </h3>
              </Col>
              <Col
                className={style.creditModalContributionContainerLeftContainer}
                sm={12}
                lg={6}
              >
                <img
                  className={style.creditModalPicture}
                  src={logoInconito}
                  alt="logo inconito"
                />
                {/* <h4 className={style.creditModalContributionLegend}>
                  inconito.fr
                </h4> */}
              </Col>
              <Col
                className={style.creditModalContributionContainerRightContainer}
                sm={12}
                lg={6}
              >
                <h3>
                  <span className={style.creditModalTitle}>Production : </span>
                  Inconito
                </h3>
              </Col>
              <Col sm={12} style={{ margin: "5rem 0 2rem 0" }}>
                <h2>Crédits photographiques</h2>
              </Col>
              <Col className={style.creditModalContent} sm={12}>
                <ol>
                  <li>
                    <h3>N°55-1978 Trois soleils IV - </h3>
                    Anna-Eva Bergman - 1978 - Acrylique et feuille de métal sur
                    papier marouflé sur panneau de bois - 12,4 x 19,9 cm -
                    Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3>N°49-1969 Paysage nordique -</h3> Anna-Eva Bergman -
                    1969 - Vinylique et feuille de métal sur panneau de bois
                    contreplaqué - 146 x 98 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3>N°39-1983 - </h3>Anna-Eva Bergman - 1983 - Acrylique sur
                    toile - 81 x 100 cm - Musée d’Art moderne de Paris
                  </li>
                  <li>
                    <h3>N°18-1976 Montagne rouge - </h3>Anna-Eva Bergman - 1976
                    - Acrylique et feuille de métal sur toile - 180 x 180 cm -
                    Musée d’Art moderne de Paris
                  </li>
                  <li>
                    <h3>N°46-1978 Deux pics de montagne - </h3>Anna-Eva Bergman
                    - 1978 - Acrylique et feuille de métal sur papier marouflé
                    sur panneau de bois - 11,7 x 15,5 cm - Fondation
                    Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°42-1978 Cap bleu - </h3>Anna-Eva Bergman - 1978 -
                    Acrylique et feuille de métal sur panneau de bois Isorel -
                    14 x 18 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°26-1986 - </h3>Anna-Eva Bergman - 1986 -Acrylique,
                    modeling paste et feuille de métal sur toile - 12 x 22 cm -
                    Musée d’Art moderne de Paris
                  </li>
                  <li>
                    <h3> N°22-1981 Horizon d’argent - </h3>Anna-Eva Bergman -
                    1981 - Acrylique et feuille de métal sur toile - 180 x 250
                    cm - Musée d’Art moderne de Paris
                  </li>
                  <li>
                    <h3>N°34-1978 Barque -</h3> Anna-Eva Bergman - 1978 -
                    Acrylique et feuille de métal sur carton marouflé sur
                    panneau de bois - 11,6 x 15,5 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°12-1978 Montagne II - </h3>1978 - Acrylique et
                    feuille de métal sur carton marouflé sur panneau de bois
                    10,7 x 14 cm Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°21-1978 Hiver – Jour I - </h3>Anna-Eva Bergman -1978
                    - Acrylique et encre sur carton marouflé sur panneau de bois
                    - 11,7 x 15,4 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°22-1978 Hiver – Jour II - </h3> Anna-Eva Bergman 1978
                    - Acrylique et feuille de métal sur papier marouflé sur
                    panneau de bois - 11,7 x 15,5 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°23-1978 Hiver – Jour III - </h3> Anna-Eva Bergman -
                    1978 - Acrylique sur carton marouflé sur panneau de bois -
                    11,6 x 15,4 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3>N°24-1978 Hiver – Jour IV - </h3> Anna-Eva Bergman -
                    1978- Acrylique et feuille de métal sur papier marouflé sur
                    panneau de bois - 11,5 x 15,4 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°42-1978 Cap bleu - </h3> Anna-Eva Bergman - 1978 -
                    Acrylique et feuille de métal sur panneau de bois Isorel- 14
                    x 18 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°46-1978 Deux pics de montagne - </h3> Anna-Eva
                    Bergman - 1978 - Acrylique et feuille de métal sur papier
                    marouflé sur panneau de bois - 11,7 x 15,5 cm - Fondation
                    Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°47-1978 Pic de montagne - </h3>Anna-Eva Bergman -
                    1978 - Acrylique et feuille de métal sur papier marouflé sur
                    panneau de bois 11,7 x 15,5 cm - Fondation Hartung-Bergman
                  </li>
                  <li>
                    <h3> N°52-1978 Pic de montagne argenté - </h3> Anna-Eva
                    Bergman - 1978 - Acrylique et feuille de métal sur carton
                    marouflé sur panneau de bois - 11,6 x 15,5 cm - Fondation
                    Hartung-Bergman
                  </li>
                </ol>
              </Col>
              <Col sm={12} style={{ margin: "5rem 0 1rem 0" }}>
                <h2>Photographies</h2>
                <h3>
                  Anna-Eva Bergman peignant dans son atelier de la rue Gauguet,
                  Paris, 1962, Hans Hartung, © Hans Hartung /Adagp, Paris, 2023
                </h3>
                <h3>
                  Pêle-mêle de photographies du Cap Nord, Anna-Eva Bergman, ©
                  Anna-Eva Bergman/Adagp, Paris, 2023
                </h3>
              </Col>
              <Col sm={12} style={{ margin: "1rem 0 1rem 0" }}>
                <h2>Films</h2>
                <h3>
                  Rencontre avec les peintres Anna-Eva Bergman et Hans Hartung,
                  Réalisation : Istvan Korda Kovacs et Kari Borg Mannsåker,
                  Production : NRK, 1980 © Norsk rikskringkasting / Istvan Kord
                  Kovacs et Kari Borg Mannsåker
                </h3>
                <h3>
                  Sur les traces d'Anna-Eva Bergman, Réalisation : Vincent
                  Magescas, Production : Fondation Hartung-Bergman, 2013 ©
                  Vincent Magescas
                </h3>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }, [showModal]);

  return <>{displayModal}</>;
};

Index.propTypes = {
  onValid: PropTypes.func,
  handleShow: PropTypes.func,
  handleClose: PropTypes.func,
  showModal: PropTypes.bool,
};

export default Index;
