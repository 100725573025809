import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const GlobalScrollContext = createContext();

const GlobalScrollProvider = ({ children }) => {
  const [displayScrollBar, setDisplayScrollBar] = useState(true);

  return (
    <GlobalScrollContext.Provider
      value={{ displayScrollBar, setDisplayScrollBar }}
    >
      {children}
    </GlobalScrollContext.Provider>
  );
};

GlobalScrollProvider.propTypes = {
  children: PropTypes.any,
};

export default GlobalScrollProvider;
