import { useState, useRef } from "react";

export default function useRefState(initialValue) {
  const [state, _setState] = useState(initialValue);

  const stateRef = useRef(state);
  const setState = (data) => {
    stateRef.current = data;
    _setState(data);
  };
  const getState = () => {
    return stateRef.current;
  };

  return [state, setState, getState];
}
