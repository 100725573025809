import Color from "color";

const DRAW_TOLERANCE = 3;
const OFFSET_VARIANCE = 0;
const OFFSET_OVER_DISTANCE = 0.1;
export class SimpleBrush {
  constructor(width, hexColor) {
    this.lastPos = null;
    this.width = width;
    this.brushDirection = null;
    this.drawingStarted = false;
    this.points = [];
    const color = Color(hexColor);
    this.points.push({
      baseOffset: width / 2,
      targetOffset: width / 2,
      currentOffset: width / 2,
      color: color,
      size: width,
    });

    this.color = color;
  }

  draw(ctx, targetPos) {
    if (!this.lastPos) {
      this.lastPos = targetPos;
      return;
    }
    if (
      Math.abs(targetPos.x - this.lastPos.x) < DRAW_TOLERANCE &&
      Math.abs(targetPos.y - this.lastPos.y) < DRAW_TOLERANCE
    ) {
      return;
    }
    // with 90 deg rotation
    const direction = {
      x: -(targetPos.y - this.lastPos.y),
      y: targetPos.x - this.lastPos.x,
    };
    const magnitude = Math.sqrt(
      Math.pow(direction.x, 2) + Math.pow(direction.y, 2)
    );

    let oldDirection = this.brushDirection;

    this.brushDirection = {
      x: direction.x / magnitude,
      y: direction.y / magnitude,
    };
    if (!oldDirection) {
      oldDirection = this.brushDirection;
    }

    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    this.points.forEach((point) => {
      ctx.lineWidth = point.size;
      ctx.strokeStyle = point.color;
      ctx.beginPath();
      ctx.moveTo(
        this.lastPos.x + point.currentOffset * oldDirection.x,
        this.lastPos.y + point.currentOffset * oldDirection.y
      );
      if (point.currentOffset >= point.targetOffset) {
        point.currentOffset -= OFFSET_OVER_DISTANCE;
        if (point.currentOffset <= point.targetOffset) {
          point.targetOffset =
            point.baseOffset +
            Math.random() * OFFSET_VARIANCE * 2 -
            OFFSET_VARIANCE;
        }
      } else {
        point.currentOffset += OFFSET_OVER_DISTANCE;
        if (point.currentOffset >= point.targetOffset) {
          point.targetOffset =
            point.baseOffset +
            Math.random() * OFFSET_VARIANCE * 2 -
            OFFSET_VARIANCE;
        }
      }
      ctx.lineTo(
        targetPos.x + point.currentOffset * this.brushDirection.x,
        targetPos.y + point.currentOffset * this.brushDirection.y
      );
      ctx.stroke();
      ctx.closePath();
    });
    this.lastPos = targetPos;
  }
}
