export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(" electron/") !== -1;
};
export const distanceBetween = (point1, point2) => {
  return Math.sqrt(
    Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
  );
};
export const angleBetween = (point1, point2) => {
  return Math.atan2(point2.x - point1.x, point2.y - point1.y);
};

export const canvasToPng = (canvas) => {
  return canvas.current.toDataURL("image/jpeg", 1.0);
};
