import React, { useMemo, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailContext } from "../../Context/EmailProvider";
import CtaBtn from "../../Components/Btn/CtaBtn";
import { Col } from "react-bootstrap";
import Stepper from "../Stepper";
import PicturesSample from "../PictureSample/PicturesSample";

import Form from "react-bootstrap/Form";

import style from "./SideMenu.module.scss";

const SideMenu = () => {
  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));
  const display = process.env.REACT_APP_DISPLAY;
  const { email, setEmail, submitEmail, setSubmitEmail } =
    useContext(EmailContext);

  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  useEffect(() => {
    if (submitEmail && validateEmail() === true) {
      sendEmail();
    }
    setSubmitEmail(false);
  }, [submitEmail]);

  const sendEmail = () => {
    setSubmitEmail(true);
    navigate("/congratulation");
  };

  const validateEmail = () => {
    if (email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return selectedLangage === "fr"
        ? "L'adresse email saisie est invalide!"
        : "You have entered an invalid email address !";
    }
  };

  const displayDrawerSideMenu = useMemo(() => {
    if (currentPath === "/draw") {
      return (
        <Col className={style.sideMenuContainer} md={12}>
          <Stepper />
          <PicturesSample />
        </Col>
      );
    } else if (currentPath === "/select-canvas") {
      return (
        <Col className={style.exportSideMenuContainer} md={12}>
          <h2 className={style.step5Title}>{langageJson.cutTitle}</h2>
        </Col>
      );
    } else if (currentPath === "/send-canvas") {
      if (display === "touchpad") {
        return (
          <>
            <Col className={style.exportSideMenuContainer} md={12}>
              <h3 className={style.step5Subtitle}>{langageJson.cutCta}</h3>
              <Form className={style.sideMenuForm}>
                <Form.Group>
                  <Form.Control
                    placeholder={
                      selectedLangage === "fr"
                        ? "votreadresse@exemple.com"
                        : "youraddress@exemple.com"
                    }
                    className={style.sideMenuInputForm}
                    defaultValue={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Text className={style.sideMenuInputFormAlert} muted>
                    {validateEmail()}
                  </Form.Text>
                </Form.Group>
                <div className={style.sideMenuFormBtnContainer}>
                  <CtaBtn
                    label={langageJson.endCta}
                    isActive={true}
                    style={{}}
                    disabled={!email || typeof validateEmail() != "boolean"}
                    onClick={() => {
                      sendEmail();
                    }}
                    className={style.sideMenuFormBtn}
                  />
                </div>
              </Form>
            </Col>
          </>
        );
      }
    } else if (currentPath === "/congratulation") {
      return (
        <>
          <Col className={style.congratulationsideMenuContainer} md={12}></Col>
        </>
      );
    }
  }, [currentPath, email]);

  return <>{displayDrawerSideMenu}</>;
};

export default SideMenu;
