import React, { useState, useMemo, useEffect } from "react";
import "./changeOrientation.scss";
import useScreenOrientation from "react-hook-screen-orientation";
import useRefState from "Utils/hooks/useRefState";

const ChangeOrientation = () => {
  const screenOrientation = useScreenOrientation();
  const width = window.innerWidth;

  const [screenNeedDisplay, setScreenNeedDisplay] = useState(false);
  const [
    displayRotationScreen,
    setDisplayRotationScreen,
    getDisplayRotationScreen,
  ] = useRefState(false);

  useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)");

    portrait.addEventListener("change", (event) => {
      // Check if orientation is portrait
      if (event.matches) {
        setDisplayRotationScreen(true);
      } else {
        setDisplayRotationScreen(false);
      }
    });
  }, []);

  const displayScreen = useMemo(() => {
    if (screenOrientation?.includes("portrait") || getDisplayRotationScreen()) {
      return (
        <div className="change-rotation-container">
          <div className="change-rotation-icon-container">
            <div className="phone"></div>
            <p className="change-rotation-label">
              Veuillez tourner votre appareil
            </p>
          </div>
        </div>
      );
    }
  }, [screenOrientation, getDisplayRotationScreen()]);

  return <>{displayScreen}</>;
};

export default ChangeOrientation;
