import React, { useEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";

import useRefState from "Utils/hooks/useRefState";
import { EmailContext } from "../../Context/EmailProvider";

import "simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";

const eng = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "q w e r t y u i o p ç ",
    " a s d f g h j k l {enter}",
    "{shift} z x c v b n m {shift}",
    "! # $ % & ' * + - / = ? ^ _ ",
    ".com .fr @ . ` { | } ~",
  ],
  shift: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "Q W E R T Y U I O P",
    "A S D F G H J K L {enter}",
    "{shift} Z X C V B N M < > ? {shift}",
    "! # $ % & ' * + - / = ? ^ _ ",
    ".com .fr @ . ` { | } ~",
  ],
  display: {
    "{bksp}": "DELETE",
    "{enter}": "SEND",
    "{shift}": "MAJ",
    "{tab}": "TAB",
    "{space}": "SPACE",
  },
};

const fr = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "a z e r t y u i o p é è ç",
    "q s d f g h j k l m {enter}",
    "{shift} w x c v b n {shift}",
    "! # $ % & ' * + - / = ? ^ _ ",
    ".com .fr @ . ` { | } ~",
  ],
  shift: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "A Z E R T Y U I O P É È Ç",
    "Q S D F G H J K L M Ù £ {enter}",
    "{shift} W X C V B N {shift}",
    "! # $ % & ' * + - / = ? ^",
    ".com .fr @ .  _ ` { | } ~",
  ],
  display: {
    "{bksp}": "SUPPRIMER",
    "{enter}": "ENVOYER",
    "{shift}": "MAJ",
    "{tab}": "TAB",
    "{space}": "ESPACE",
  },
};

const Index = () => {
  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));
  const { setEmail, setSubmitEmail } = useContext(EmailContext);
  const [keyboardCaps, setKeyboardCaps, getKeyboardCaps] = useRefState();
  const [keyboardLangage, setKeyboardLangage, getKeyboardLangage] =
    useRefState();
  const currentPath = useLocation().pathname;
  const display = process.env.REACT_APP_DISPLAY;

  useEffect(() => {
    setKeyboardLangage(selectedLangage === "fr" ? fr : eng);
    setKeyboardCaps(false);
  }, [selectedLangage]);

  const onKeyPress = (button) => {
    if (button === "{shift}") {
      setKeyboardCaps(!getKeyboardCaps());
    }
    if (button === "{enter}") {
      setSubmitEmail(true);
    }
  };

  const displayKeyboard = useMemo(() => {
    if (
      getKeyboardLangage() &&
      currentPath === "/send-canvas" &&
      display === "touchpad"
    ) {
      return (
        <Keyboard
          onChange={(e) => {
            setEmail(e);
          }}
          onKeyPress={(button) => {
            onKeyPress(button);
          }}
          layout={{
            default: getKeyboardCaps()
              ? getKeyboardLangage().shift
              : getKeyboardLangage().default,
          }}
          display={getKeyboardLangage().display}
        />
      );
    }
  }, [getKeyboardCaps(), getKeyboardLangage(), currentPath]);

  return <>{displayKeyboard}</>;
};

export default Index;
