import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import style from "./CtaBtn.module.scss";

const CtaBtn = (props) => {
  const [mouseIsOver, setMouseIsOver] = useState(false);

  const {
    label,
    iconPosition,
    iconDark,
    isActive,
    iconLight,
    onClick,
    className,
    disabled,
  } = { ...props };

  return (
    <Button
      onMouseEnter={() => setMouseIsOver(true)}
      onMouseLeave={() => setMouseIsOver(false)}
      onClick={onClick}
      disabled={disabled}
      className={`${style.cta}  ${iconPosition === "end" && style.reverse} ${
        isActive && !mouseIsOver ? style.isActive : ""
      }${className ? className : ""}`}
    >
      {iconLight && iconDark && (
        <img
          className={style.picture}
          src={mouseIsOver || isActive ? iconLight : iconDark}
        />
      )}
      <span>{label.toUpperCase()}</span>
    </Button>
  );
};

CtaBtn.propTypes = {
  iconDark: PropTypes.string,
  iconLight: PropTypes.string,
  iconPosition: PropTypes.string,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  darkBtn: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CtaBtn;
