import React, { useContext } from "react";
import PropTypes from "prop-types";
import { StepperContext } from "../../Context/StepperProvider";

const UndercoatCanvas = ({
  undercoatCanvas,
  dimensions,
  undercoatOverlayCanvas,
  scale,
  getUndercoat,
}) => {
  const step = useContext(StepperContext);

  return (
    <>
      <canvas
        ref={undercoatCanvas}
        width={dimensions.width}
        height={dimensions.height}
        style={{
          position: "absolute",
          opacity: getUndercoat() || step.currentStep != 3 ? 0 : 1,
          width: dimensions.width / scale + "px",
          height: dimensions.height / scale + "px",
        }}
      />
      <canvas
        ref={undercoatOverlayCanvas}
        width={dimensions.width}
        height={dimensions.height}
        style={{
          position: "absolute",
          opacity: 0,
          width: dimensions.width / scale + "px",
          height: dimensions.height / scale + "px",
        }}
      />
    </>
  );
};

UndercoatCanvas.propTypes = {
  undercoatCanvas: PropTypes.object,
  dimensions: PropTypes.object,
  undercoatOverlayCanvas: PropTypes.object,
  scale: PropTypes.number,
  getUndercoat: PropTypes.func,
};

export default UndercoatCanvas;
