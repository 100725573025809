import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PicturesSampleIllustration from "../../pictures/planche-contact.jpg";
import PictureSampleCtaIllustration from "../../pictures/bloc-photos.png";
import CircularBtn from "../Btn/CircularBtn";
import closeIcon from "../../icons/close-icon.svg";
import LookMoreIcon from "../../icons/look-more-icon.svg";
import style from "./PictureSample.module.scss";

const PicturesSample = () => {
  const [sampleModalIsOpen, setSampleModalIsOpen] = useState(false);
  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));

  return (
    <>
      <Button
        className={style.sampleModalBtn}
        onClick={() => {
          setSampleModalIsOpen(true);
        }}
      >
        <img
          className={style.picturesSampleBtnIllustration}
          src={PictureSampleCtaIllustration}
        />
        <p className={style.picturesSampleBtn}>
          <img src={LookMoreIcon} alt="voir plus" />
          {selectedLangage && langageJson.getInspirationCta}
        </p>
      </Button>
      <Modal
        show={sampleModalIsOpen}
        onHide={() => {
          setSampleModalIsOpen(false);
        }}
        aria-labelledby="picturesSample-modal"
        dialogClassName={style.sampleModalContainer}
        contentClassName={style.sampleModalContent}
        size="xl"
        centered
      >
        <Modal.Body className={style.sampleModalBody}>
          <div className={style.sampleModalCloseBtnContainer}>
            <CircularBtn
              iconDark={closeIcon}
              iconLight={closeIcon}
              className={style.closeModalBtn}
              onClick={() => {
                setSampleModalIsOpen(false);
              }}
            />
          </div>
          <img
            className={style.sampleModalIllustration}
            src={PicturesSampleIllustration}
            alt="créations d'Anna-Eva Bergman"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PicturesSample;
