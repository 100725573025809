import React, { useState, createContext } from "react";
import GDPRManager from "../Components/GDPRManager";

import PropTypes from "prop-types";

const RGPDContext = createContext({
  editGDPR: false,
  setEditGDPR: () => {},
});

const RGPDProvider = ({ children }) => {
  const [editGDPR, setEditGDPR] = useState(false);

  return (
    <RGPDContext.Provider value={{ editGDPR, setEditGDPR }}>
      <GDPRManager editGDPR={editGDPR} setEditGDPR={setEditGDPR} />
      {children}
    </RGPDContext.Provider>
  );
};

RGPDProvider.propTypes = {
  children: PropTypes.any,
};

export default RGPDProvider;
