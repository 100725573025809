import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import style from "./Header.module.scss";
import logo from "../../pictures/logo.svg";
import { Container, Row, Col } from "react-bootstrap";
import parisMuseeLogo from "../../pictures/paris-musee-logo.svg";
import mamLogo from "../../pictures/mam-logo.svg";
import facebookIcon from "../../icons/facebook-icon.svg";
import twitterIcon from "../../icons/twitter-icon.svg";
import tripadvisorIcon from "../../icons/tripadvisor-icon.svg";
import instagramIcon from "../../icons/instagram-icon.svg";

const Header = ({ isMobileDevice }) => {
  const width = window.innerWidth;
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const currentPath = useLocation().pathname;

  const displayLogo = useMemo(() => {
    if (currentPath != "/select-langage") {
      return (
        <Navbar.Brand>
          <img className={`${style.logo}`} src={logo} alt="Logo" />
        </Navbar.Brand>
      );
    }
  }, [currentPath]);

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const displayNavBar = useMemo(() => {
    if (
      currentPath != "/select-langage" &&
      currentPath != "/draw" &&
      dimensions.width > 900 &&
      process.env.REACT_APP_DISPLAY != "touchpad"
    ) {
      return (
        <div className={style.headerNavbarContainer}>
          <Nav className={style.headerNavbar}>
            <Nav.Link
              href="https://www.parismusees.paris.fr/fr"
              target="_blank"
            >
              <img
                className={style.navItem}
                src={parisMuseeLogo}
                alt="logo Paris musée"
              />
            </Nav.Link>
            <Nav.Link href="https://www.mam.paris.fr" target="_blank">
              <img
                className={style.navItem}
                src={mamLogo}
                alt="logo musee d'art moderne"
              />
            </Nav.Link>
          </Nav>
          <Nav className={style.headerNavbarsocialsLink}>
            <Nav.Link href="https://www.facebook.com/MAMParis" target="_blank">
              <img src={facebookIcon} alt="lien facebook" />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/MAM" target="_blank">
              <img src={twitterIcon} alt="lien twitter" />
            </Nav.Link>
            <Nav.Link
              href="https://www.tripadvisor.fr/Attraction_Review-g187147-d188486-Reviews-Musee_d_Art_Moderne_de_Paris-Paris_Ile_de_France.html"
              target="_blank"
            >
              <img src={tripadvisorIcon} alt="lien tripadvisor" />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/museedartmodernedeparis/"
              target="blank"
            >
              <img src={instagramIcon} alt="lien instragram" />
            </Nav.Link>
          </Nav>
        </div>
      );
    }
  }, [currentPath, dimensions]);
  return (
    <header>
      <Container fluid>
        {currentPath != "/video" && (
          <Row className={style.headerContainer}>
            <Col sm={4} className={style.headerLogoContainer}>
              {displayLogo}
            </Col>
            <Col sm={8}>{displayNavBar}</Col>
          </Row>
        )}
      </Container>
    </header>
  );
};

Header.propTypes = {
  isMobileDevice: PropTypes.bool,
};

export default Header;
