import React, { useMemo, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import style from "./Modal.module.scss";
import CircularBtn from "Components/Btn/CircularBtn";
import CtaBtn from "Components/Btn/CtaBtn";
import { Container, Row, Col } from "react-bootstrap";
import { ModalContext } from "../../Context/ModalProvider";

import closeIcon from "../../icons/close-icon.svg";
import nextDarkIcon from "../../icons/cta-arrowDark-icon.svg";
const Index = (props) => {
  const { modalValues, onValid, handleShow, handleClose, showModal } = props;
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));

  const modalContext = useContext(ModalContext);

  useEffect(() => {
    modalContext.setModalIsOpen(showModal ? true : false);
  }, [showModal]);

  const displayModal = useMemo(() => {
    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
        dialogClassName={style.popinModalContainer}
      >
        <Modal.Body>
          <Container>
            <Row>
              {modalValues.showClose && (
                <Col className={style.popinModalCloseBtnContainer} sm={12}>
                  <CircularBtn
                    iconDark={closeIcon}
                    iconLight={closeIcon}
                    className={style.popinModalCloseBtn}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </Col>
              )}
              <Col sm={12}>
                <h2 className={style.popinmodalTitle}>{modalValues.title}</h2>
              </Col>
              <Col sm={12}>
                <div className={style[modalValues.className]}>
                  {modalValues.content}
                </div>
              </Col>
              {modalValues.showConfirm && (
                <Col sm={12} className={style.popinModalFooterBtnContainer}>
                  <CtaBtn
                    label={langageJson.gotItCta}
                    iconDark={nextDarkIcon}
                    iconLight={nextDarkIcon}
                    className={style.poppinModalFooterCta}
                    iconPosition="end"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }, [showModal, modalValues]);

  return <>{displayModal}</>;
};

Index.propTypes = {
  modalValues: PropTypes.object,
  onValid: PropTypes.func,
  handleShow: PropTypes.func,
  handleClose: PropTypes.func,
  showModal: PropTypes.bool,
};

export default Index;
