import React, { useState, useRef, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { StepperContext } from "../../Context/StepperProvider";
import { UndercoatContext } from "../../Context/UndercoatProvider";
import { ScreenSaverContext } from "../../Context/ScreenSaverProvider";

import CtaBtn from "../../Components/Btn/CtaBtn";
import CircularBtn from "../../Components/Btn/CircularBtn";
import refreshDarkIcon from "../../icons/refresh-dark-icon.svg";
import refreshLightIcon from "../../icons/refresh-light-icon.svg";
import returnLightIcon from "../../icons/return-light-icon.svg";
import returnDarkIcon from "../../icons/return-dark-icon.svg";
import tapeIcon from "../../icons/tape-icon.svg";
import smallBrush from "../../icons/small-brush-icon.svg";
import mediumBrushDark from "../../icons/medium-brushDark-icon.svg";
import smallSpalterDark from "../../icons/small-spalterDark-icon.svg";
import largeSpalterDark from "../../icons/large-spalterDark-icon.svg";
import mediumBrushLight from "../../icons/medium-brushLight-icon.svg";
import smallSpalterLight from "../../icons/small-spalterLight-icon.svg";
import largeSpalterLight from "../../icons/large-spalterLight-icon.svg";
import scissors from "../../icons/scissors-icon.svg";

import Modal from "../../Components/Modal";
import style from "./Drawer.module.scss";
import InteractiveCanvas from "./InteractiveCanvas";
import firstColor from "../../Components/Modal/ColorsBtnBackground/1.jpg";
import secondColor from "../../Components/Modal/ColorsBtnBackground/2.jpg";
import thirdColor from "../../Components/Modal/ColorsBtnBackground/3.jpg";
import fourthColor from "../../Components/Modal/ColorsBtnBackground/4.jpg";
import fifthColor from "../../Components/Modal/ColorsBtnBackground/5.jpg";

import useRefState from "Utils/hooks/useRefState";

const Drawer = () => {
  const [canvasSize, setCanvasSize, getCanvasSize] = useRefState(null);
  const canvasContainer = useRef(null);
  const scale = 2;

  const pathName = useLocation().pathname;

  const step = useContext(StepperContext);

  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));
  const width = window.innerWidth;

  const contextUndercoat = useContext(UndercoatContext);
  const { screenSaverIsRunning } = useContext(ScreenSaverContext);

  const [showModal, setShowModal, getShowModal] = useRefState(false);
  const [showColorPickModal, setShowColorPickModal, getShowColorPickModal] =
    useRefState(false);

  const [snapStepCanvas, setSnapStepCanvas, getSnapStepCanvas] = useRefState(
    []
  );
  const [undercoat, setUndercoat, getUndercoat] = useRefState();
  const [underCoatIsApply, setUnderCoatIsApply, getUnderCoatIsApply] =
    useRefState(false);

  const [
    snapStepUndercoatCanvas,
    setSnapStepUndercoatCanvas,
    getSnapStepUndercoatCanvas,
  ] = useRefState([]);

  const [canvasNeedUndo, setCanvasNeedUndo, getCanvasNeedUndo] =
    useRefState(false);

  const [modalValues, setModalValues] = useState({
    title: "",
    content: "",
    showConfirm: false,
    showClose: false,
    showTitle: false,
  });
  const [
    stepThreeAlreadyLoaded,
    setStepThreeAlreadyLoaded,
    getStepThreeAlreadyLoaded,
  ] = useRefState(false);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedUndercoatColor, setSelectedUndercoatColor] = useState();
  const [resetCanvas, setResetCanvas] = useState(false);
  const [snapCanvas, setSnapCanvas, getSnapCanvas] = useRefState({});
  const [snapUndercoatCanvas, setSnapUndercoatCanvas] = useState({});
  const [iconsList, setIconsList] = useState([{}]);
  const [selectedBrush, setSelectedBrush] = useState(50);
  const [activeBrushIndex, setActiveBrushIndex] = useState(1);

  const handleClose = () => {
    if (step.currentStep === 4 && !getShowColorPickModal()) {
      setShowColorPickModal(true);
    }
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (screenSaverIsRunning) {
      setShowModal(false);
    }
  }, [screenSaverIsRunning]);

  useEffect(() => {
    step.setFinishedSteps([]);
    step.setCurrentStep(1); // ! temp
  }, []);

  useEffect(() => {
    const { currentStep } = step;

    if (currentStep === 1) {
      setActiveBrushIndex(1);
      setIconsList([
        {
          index: 1,
          iconLight: tapeIcon,
          iconDark: tapeIcon,
          label: langageJson.usingKraftTool,
        },
      ]);
    } else if (currentStep === 2 || currentStep === 3) {
      setIconsList([
        {
          index: 1,
          iconLight: smallBrush,
          iconDark: smallBrush,
          label: langageJson.drawTool,
          brushWidth: 50,
        },
      ]);
    } else if (currentStep === 4) {
      setIconsList([
        {
          index: 1,
          iconLight: mediumBrushDark,
          iconDark: mediumBrushLight,
          label: langageJson.applyBackgroundTools[0],
          brushWidth: 50,
        },
        {
          index: 2,
          iconLight: smallSpalterDark,
          iconDark: smallSpalterLight,
          label: langageJson.applyBackgroundTools[1],
          brushWidth: 100,
        },
        {
          index: 3,
          iconLight: largeSpalterDark,
          iconDark: largeSpalterLight,
          label: langageJson.applyBackgroundTools[2],
          brushWidth: 250,
        },
      ]);
    } else if (currentStep === 5) {
      setIconsList([
        {
          index: 1,
          iconLight: scissors,
          iconDark: scissors,
          label: langageJson.cutTool,
        },
      ]);
    }
  }, [step.currentStep]);

  useEffect(() => {
    setSelectedColor("#1E3062");
  }, []);

  useEffect(() => {
    if (pathName) {
      sessionStorage.setItem("pathName", pathName);
    }
  }, [pathName]);

  useEffect(() => {
    getCanvaResize();
  }, []);

  useEffect(() => {
    if (
      (!getUnderCoatIsApply() || step.currentStep !== 3) &&
      step.currentStep !== 1
    ) {
      const newSnapStepCanvas = [...getSnapStepCanvas()];
      if (getSnapCanvas().localName === "canvas") {
        setSnapStepCanvas([...newSnapStepCanvas, getSnapCanvas()]);
      }
    }
  }, [getSnapCanvas(), snapUndercoatCanvas]);

  useEffect(() => {
    if (step.currentStep === 3 && !getUnderCoatIsApply()) {
      const newSnapStepCanvas = [...getSnapStepUndercoatCanvas()];
      if (snapUndercoatCanvas.localName === "canvas") {
        setSnapStepUndercoatCanvas([...newSnapStepCanvas, snapUndercoatCanvas]);
      }

      if (getUndercoat()) {
        setUnderCoatIsApply(true);
      }
    }
  }, [snapUndercoatCanvas, getSnapCanvas()]);

  const getCanvaResize = () => {
    setCanvasSize({
      width: canvasContainer.current
        ? canvasContainer.current.offsetWidth * scale
        : 0,
      height: canvasContainer.current.offsetHeight * scale,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", getCanvaResize);
  }, []);

  useEffect(() => {
    if (getShowColorPickModal()) {
      setModalValues({
        title: langageJson?.applyBackgroundChoice,
        content: displayColorList(),
        className: "popinModalColorContainer",
        showConfirm: false,
        showClose: false,
      });
      handleShow();
    }
  }, [getShowColorPickModal()]);

  useEffect(() => {
    setTimeout(() => {
      if (step.currentStep === 1) {
        setModalValues({
          title:
            selectedLangage === "fr"
              ? "ÉTAPE 1 - QUADRILLAGE"
              : "STEP 1 - SQUARE OFF",
          content: langageJson?.usingKraftPopIn,
          showConfirm: true,
          showClose: true,
          className: "popinModalContent",
        });
        handleShow();
      } else if (step.currentStep === 2) {
        setModalValues({
          title: selectedLangage === "fr" ? "ÉTAPE 2" : "STEP 2",
          content: langageJson?.drawPopIn,
          showConfirm: true,
          showClose: true,
          className: "popinModalContent",
        });
        handleShow();
      } else if (
        step.currentStep === 3 &&
        contextUndercoat.selectedColor == null &&
        !contextUndercoat.selectedColor &&
        !getStepThreeAlreadyLoaded()
      ) {
        setModalValues({
          title: selectedLangage === "fr" ? "ÉTAPE 3" : "STEP 3",
          content: langageJson?.undercoatPopIn,
          showConfirm: true,
          showClose: true,
          className: "popinModalContent",
        });
        handleShow();
        setStepThreeAlreadyLoaded(true);
      } else if (step.currentStep === 4) {
        setUnderCoatIsApply(false);
        setUndercoat(false);

        setModalValues({
          title: selectedLangage === "fr" ? "ÉTAPE 4" : "STEP 4",
          content: langageJson?.applyBackgroundPopIn,
          className: "popinModalContent",
          showConfirm: true,
          showClose: true,
        });
        handleShow();
      } else if (step.currentStep === 5) {
        setModalValues({
          title: selectedLangage === "fr" ? "ÉTAPE 5" : "STEP 5",
          content: langageJson?.cutPopIn,
          showConfirm: true,
          showClose: true,
          className: "popinModalContent",
        });
        handleShow();
      }
    }, 300);
  }, [step.currentStep, contextUndercoat]);

  const onSelectColorClick = (color) => {
    setSelectedColor((oldSelectedColor) => {
      let newSelectedColor = oldSelectedColor;
      newSelectedColor = color;
      return newSelectedColor;
    });
    handleClose();
  };

  const onSelectUndercoatColorClick = (color) => {
    setSelectedUndercoatColor((oldSelectedColor) => {
      let newSelectedColor = oldSelectedColor;
      newSelectedColor = color;
      return newSelectedColor;
    });
  };
  const onResetCanvasClick = () => {
    setShowColorPickModal(false);
    setUnderCoatIsApply(false);
    setResetCanvas(true);
    setSnapStepUndercoatCanvas([]);
    setSnapStepCanvas([]);
    setSnapUndercoatCanvas({});
    setSelectedBrush(50);
    setSnapCanvas({});
    step.setCurrentStep(1);
    setStepThreeAlreadyLoaded(false);
  };

  const onReturnClick = () => {
    const newSnapStepCanvas = [...getSnapStepCanvas()];
    const newSnapStepUndercoatCanvas = [...getSnapStepUndercoatCanvas()];

    newSnapStepCanvas.splice(-1);
    newSnapStepUndercoatCanvas.splice(-1);

    if (newSnapStepCanvas.length > 0) {
      setSnapStepCanvas(newSnapStepCanvas);
      setCanvasNeedUndo(true);

      if (newSnapStepCanvas.length === 1) {
        step.setFinishedSteps((oldStepsList) => {
          const newStepList = [...oldStepsList];
          if (newStepList.includes(step.currentStep)) {
            const index = newStepList.indexOf(step.currentStep);
            const x = newStepList.splice(index, 1);
          }

          return newStepList;
        });
      }
    }

    if (newSnapStepUndercoatCanvas.length > 0) {
      setSnapStepUndercoatCanvas(newSnapStepUndercoatCanvas);

      if (newSnapStepUndercoatCanvas.length === 1) {
        step.setFinishedSteps((oldStepsList) => {
          const newStepList = [...oldStepsList];
          if (newStepList.includes(step.currentStep)) {
            const index = newStepList.indexOf(step.currentStep);
            const x = newStepList.splice(index, 1);
          }

          return newStepList;
        });
      }
    }
  };

  const endStep = (getCurrentStep) => {
    step.setFinishedSteps((oldStepsList) => {
      const newStepList = [...oldStepsList];
      newStepList.push(getCurrentStep());

      return newStepList;
    });
  };

  const displayColorList = () => {
    return (
      <div>
        <Button
          className={style.popinModalColorBtn}
          onClick={() => {
            onSelectColorClick("#1E3062");
          }}
        >
          <img src={firstColor} />
        </Button>
        <Button
          className={style.popinModalColorBtn}
          onClick={() => {
            onSelectColorClick("#6A92AD");
          }}
        >
          <img src={secondColor} />
        </Button>
        <Button
          className={style.popinModalColorBtn}
          onClick={() => {
            onSelectColorClick("#3C6275");
          }}
        >
          <img src={thirdColor} />
        </Button>
        <Button
          className={style.popinModalColorBtn}
          onClick={() => {
            onSelectColorClick("#8DBEC5");
          }}
        >
          <img src={fourthColor} />
        </Button>
        <Button
          className={style.popinModalColorBtn}
          onClick={() => {
            onSelectColorClick("#c3ced1");
          }}
        >
          <img src={fifthColor} />
        </Button>
      </div>
    );
  };

  const displayContent = () => {
    return (
      <>
        <Row>
          <Col
            md={12}
            className={style.drawRightContainer}
            ref={canvasContainer}
          >
            {canvasSize && (
              <InteractiveCanvas
                dimensions={getCanvasSize}
                scale={scale}
                selectedColor={selectedColor}
                resetCanvas={resetCanvas}
                setResetCanvas={setResetCanvas}
                setSnapCanvas={setSnapCanvas}
                getSnapCanvas={getSnapCanvas}
                snapUndercoatCanvas={snapUndercoatCanvas}
                setSnapUndercoatCanvas={setSnapUndercoatCanvas}
                getSnapStepUndercoatCanvas={getSnapStepUndercoatCanvas}
                endStep={endStep}
                step={step}
                selectedBrush={selectedBrush}
                contextUndercoat={contextUndercoat}
                modalOpen={getShowModal}
                setSnapStepCanvas={setSnapStepCanvas}
                getSnapStepCanvas={getSnapStepCanvas}
                getCanvasNeedUndo={getCanvasNeedUndo}
                setCanvasNeedUndo={setCanvasNeedUndo}
                canvasNeedUndo={canvasNeedUndo}
                setUndercoat={setUndercoat}
                getUndercoat={getUndercoat}
                setUnderCoatIsApply={setUnderCoatIsApply}
              />
            )}
          </Col>
          <Col
            md={step.currentStep != 1 && step.currentStep != 5 ? 4 : 3}
            xl={4}
            sm={4}
            className={style.drawerIconContainer}
          >
            {step.currentStep !== 1 && (
              <CtaBtn
                label={width > "950" ? langageJson.startNewCreationCta : ""}
                iconDark={refreshLightIcon}
                iconLight={refreshDarkIcon}
                className={style.redrawButton}
                onClick={() => {
                  onResetCanvasClick();
                }}
              />
            )}
          </Col>
          <Col
            className={style.toolsBtnContainer}
            style={{
              marginLeft:
                step.currentStep === 4 && width > "1400" ? " 5rem" : 0,
              justifyContent:
                step.currentStep === 5 && width < "1200"
                  ? "flex-end"
                  : "center",
            }}
            md={step.currentStep != 1 ? (step.currentStep != 4 ? 4 : 7) : 6}
            xl={4}
            sm={step.currentStep != 1 ? (step.currentStep != 4 ? 4 : 7) : 6}
          >
            {step.currentStep != 1 && (
              <div
                style={{
                  display: step.currentStep === 5 ? "none" : "flex",
                  marginLeft:
                    width >= 1400 && width <= 1700 && step.currentStep === 4
                      ? width < 1100
                        ? "3rem"
                        : "15rem"
                      : 0,
                }}
              >
                <CircularBtn
                  iconDark={returnLightIcon}
                  iconLight={returnDarkIcon}
                  className={style.refreshBtn}
                  onClick={() => {
                    onReturnClick();
                  }}
                />
              </div>
            )}
            {iconsList.map((icon, key) => {
              return (
                <CircularBtn
                  iconDark={icon.iconDark}
                  iconLight={icon.iconLight}
                  key={key}
                  className={style.toolBtn}
                  isActive={
                    activeBrushIndex === icon.index || iconsList.length === 1
                  }
                  label={icon.label}
                  onClick={() => {
                    setSelectedBrush(icon.brushWidth);
                    setActiveBrushIndex(icon.index);
                  }}
                />
              );
            })}
          </Col>
        </Row>
        <Modal
          handleShow={handleShow}
          handleClose={handleClose}
          showModal={showModal}
          modalValues={modalValues}
        />
      </>
    );
  };

  return (
    <Container className={style.drawMainContainer}>
      {displayContent()}
    </Container>
  );
};

export default Drawer;
