import React, { useEffect, useState, useMemo, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CtaBtn from "../../Components/Btn/CtaBtn";

import { Link } from "react-router-dom";

import style from "./MobileHomepage.module.scss";
import fr from "../../Utils/localisation/fr.json";
import en from "../../Utils/localisation/en.json";
import { LocalisationContext } from "../../Context/LocalisationProvider";

const Index = () => {
  const display = process.env.REACT_APP_DISPLAY;
  const currentPath = window.location.href;
  const langageLangageLabel = ["fr", "en"];
  const { langageJson, selectedLangage, setSelectedLangage } =
    useContext(LocalisationContext);

  const [selectedLangageJson, setselectedLangageJson] = useState(
    currentPath.includes("en") || currentPath.includes("EN") ? en : fr
  );

  const linkToMuseum = () => {
    window.location.replace(
      "https://www.mam.paris.fr/fr/expositions/exposition-anna-eva-bergman"
    );
  };

  useEffect(() => {
    currentPath.includes("en") || currentPath.includes("EN")
      ? localStorage.setItem("langageJson", JSON.stringify(en))
      : localStorage.setItem("langageJson", JSON.stringify(fr));

    currentPath.includes("en") || currentPath.includes("EN")
      ? localStorage.setItem("selectedLangage", JSON.stringify("en"))
      : localStorage.setItem("selectedLangage", JSON.stringify("fr"));
  }, []);

  useEffect(() => {
    if (selectedLangage === "en") {
      localStorage.setItem("langageJson", JSON.stringify(en));
      setselectedLangageJson(en);
    } else {
      localStorage.setItem("langageJson", JSON.stringify(fr));
      setselectedLangageJson(fr);
    }
  }, [selectedLangage]);

  const displayHeaderCta = useMemo(() => {
    return (
      <div className={style.headerCtaContainer}>
        <CtaBtn
          label={langageLangageLabel[0]}
          className={`style.headerCtaBtn ${
            langageLangageLabel[0] === selectedLangage ? style.isActive : ""
          }`}
          isActive={langageLangageLabel[0] === selectedLangage}
          onClick={() => {
            setSelectedLangage(langageLangageLabel[0]);
          }}
        />
        <CtaBtn
          className={`style.headerCtaBtn ${
            langageLangageLabel[1] === selectedLangage ? style.isActive : ""
          }`}
          label={langageLangageLabel[1]}
          onClick={() => {
            setSelectedLangage(langageLangageLabel[1]);
          }}
        />
      </div>
    );
  }, [selectedLangage]);

  return (
    <Container className={style.mobileGlobalContainer}>
      {displayHeaderCta}

      <Row className={style.mobileMainContainer}>
        <Col className={style.mobileTitleContainer} item sm={12}>
          <p>{selectedLangageJson.mobileMessage1}</p>
        </Col>
        <Col className={style.mobileTitleContainer} item sm={12}>
          <p>{selectedLangageJson.mobileMessage2}</p>
        </Col>
        <Col className={style.mobileCtaContainer} item sm={12}>
          <Link to={"/video"}>
            <CtaBtn label={selectedLangageJson.mobileCtaLabel} isActive />
          </Link>
        </Col>
        <Col className={style.mobileTitleContainer} item sm={12}>
          <p>{selectedLangageJson.mobileMessage3}</p>
        </Col>
      </Row>
    </Container>
  );
};

Index.propTypes = {};

export default Index;
