export const GA_COOKIES = ({
  containerId = null,
  propertyId = containerId,
}) => {
  let gCookiesArray = [
    "CONSENT",
    "_ga",
    "_gid",
    "_gat",
    "AMP_TOKEN",
    "__utma",
    "__utmb",
    "__utmc",
    "__utmt",
    "__utmv",
    "__utmz",
  ];
  if (containerId) {
    gCookiesArray = [
      ...gCookiesArray,
      `_gac_${propertyId}`,
      `_ga_${containerId}`,
      `_gac_gb_${containerId}`,
      `_dc_gtm_${propertyId}`,
    ];
  }
  return gCookiesArray;
};

export const FBPIXEL_COOKIES = () => ["_fbp"];

export const YOUTUBE_COOKIES = async () => {
  if (typeof document !== "undefined") {
    return [];
  }
  return [];
};
