import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import useRefState from "Utils/hooks/useRefState";
import { useNavigate } from "react-router-dom";

export const ScreenSaverContext = createContext();

const ScreenSaverProvider = ({ children }) => {
  const [screenSaverIsRunning, setScreenSaverIsRunning] = useState(true);
  const [saveresetTimerCanvas, setResetTimer, getResetTimer] =
    useRefState(false);
  const [displayvideo, setDisplayvideo] = useState(true);
  const navigate = useNavigate();
  const display = process.env.REACT_APP_DISPLAY;

  useEffect(() => {
    if (display === "touchpad") {
      setResetTimer(false);

      const timer = setTimeout(() => {
        navigate("/", { replace: true });
        setDisplayvideo(true);
      }, 300000);
      return () => clearTimeout(timer);
    }
  }, [getResetTimer()]);

  const onMouseDown = () => {
    setResetTimer(true);
    setDisplayvideo(false);
    setScreenSaverIsRunning(false);
  };
  return (
    <ScreenSaverContext.Provider
      value={{
        screenSaverIsRunning,
        setScreenSaverIsRunning,
        displayvideo,
        setDisplayvideo,
        onMouseDown,
        setResetTimer,
        getResetTimer,
      }}
    >
      {children}
    </ScreenSaverContext.Provider>
  );
};

ScreenSaverProvider.propTypes = {
  children: PropTypes.any,
};

export default ScreenSaverProvider;
