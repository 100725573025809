import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import useRefState from "Utils/hooks/useRefState";
import { Container, Row, Col, Button } from "react-bootstrap";
import { StepperContext } from "../../Context/StepperProvider";
import { UndercoatContext } from "../../Context/UndercoatProvider";
import { MiniCanvasContext } from "../../Context/MiniCanvasProvider";
import Nav from "react-bootstrap/Nav";

import style from "./Footer.module.scss";
import CtaBtn from "../../Components/Btn/CtaBtn";
import homeIcon from "../../icons/cta-home-icon.svg";
import homeIconOver from "../../icons/home-icon-hover.svg";
import nextDarkIcon from "../../icons/cta-arrowDark-icon.svg";
import Modal from "../../Components/Modal";
import CreditModal from "../../Components/Modal/CreditModal";
import goldCta from "../../Components/Modal/ColorsBtnBackground/or.jpg";
import silverCta from "../../Components/Modal/ColorsBtnBackground/argent.jpg";
import { ScreenSaverContext } from "../../Context/ScreenSaverProvider";
import mamLogo from "../../pictures/mam-logo.svg";
import parisMuseeLogo from "../../pictures/paris-musee-logo.svg";

const Footer = () => {
  const step = useContext(StepperContext);
  const contextUndercoat = useContext(UndercoatContext);
  const { selectedMiniCanvas } = useContext(MiniCanvasContext);
  const currentLocation = useLocation().pathname;
  const [currentStepIsFinish, setCurrentStepIsFinish] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));
  const selectedLangage = localStorage.getItem("selectedLangage");
  const [modalChoiceIsDone, setModalChoiceIsDone, getModalChoiceIsDone] =
    useRefState();

  const [modalValues, setModalValues] = useState({
    title: "",
    content: "",
    showConfirm: false,
    showClose: false,
    showTitle: false,
  });
  const { displayvideo } = useContext(ScreenSaverContext);

  useEffect(() => {
    if (displayvideo) {
      creditModalHandleClose();
    }
  }, [displayvideo]);
  useEffect(() => {
    if (step.currentStep === 1) {
      setModalChoiceIsDone(true);
      contextUndercoat.setSelectedColor();
    }
  }, [step]);

  const onSelectUndercoatColorClick = (color) => {
    contextUndercoat.setSelectedColor(color);
    handleClose();
  };

  const displayUndercoatColor = () => {
    return (
      <div className={style.popinModalBtnContainer}>
        <Button
          className={style.popinModalColorBtn}
          onClick={() => {
            onSelectUndercoatColorClick("gold");
          }}
        >
          <img src={goldCta} />
        </Button>
        <Button
          className={style.popinModalColorBtn}
          onClick={() => {
            onSelectUndercoatColorClick("silver");
          }}
        >
          <img src={silverCta} />
        </Button>
      </div>
    );
  };

  const handleClose = () => {
    setShowModal(false);
    setModalChoiceIsDone(true);
  };
  const handleShow = () => setShowModal(true);

  const creditModalHandleClose = () => {
    setShowCreditModal(false);
  };
  const creditModalHandleShow = () => setShowCreditModal(true);

  useEffect(() => {
    setCurrentStepIsFinish(false);

    if (
      step.finishedSteps.length > 0 &&
      step.finishedSteps.includes(step.currentStep)
    ) {
      setCurrentStepIsFinish(true);
    }
  }, [step.finishedSteps, step]);

  useEffect(() => {
    if (
      step.currentStep === 3 &&
      getModalChoiceIsDone() &&
      !contextUndercoat.selectedColor
    ) {
      setModalChoiceIsDone(false);
    }
  }, [step, contextUndercoat.selectedColor]);

  const nextStep = () => {
    if (!getModalChoiceIsDone() && langageJson && step.currentStep === 3) {
      setModalValues({
        title:
          selectedLangage === "fr"
            ? `ÉTAPE 3 ${langageJson.undercoatChoice}`
            : `STEP 3 ${langageJson.undercoatChoice}`,
        content: displayUndercoatColor(),
        showConfirm: false,
        showClose: false,
        className: "popinModalContent",
      });
      handleShow();
    }
    if (getModalChoiceIsDone()) {
      step.setCurrentStep(step.currentStep + 1);
      return step.currentStep;
    }
  };

  const displayNextCta = useMemo(() => {
    if (currentLocation != "/send-canvas" && selectedLangage) {
      return (
        <CtaBtn
          label={langageJson?.nextCta}
          iconDark={nextDarkIcon}
          iconLight={nextDarkIcon}
          className={style.footerCta}
          disabled={!currentStepIsFinish}
          onClick={() => {
            nextStep();
          }}
        />
      );
    }
  }, [langageJson, currentStepIsFinish]);

  const displayLinkCta = () => {
    if (currentLocation != "/send-canvas") {
      return (
        <Link
          style={{ pointerEvents: !currentStepIsFinish ? "none" : "" }}
          to={"/select-canvas"}
        >
          <CtaBtn
            label={langageJson?.nextCta}
            iconDark={nextDarkIcon}
            iconLight={nextDarkIcon}
            className={style.footerCta}
            disabled={!currentStepIsFinish}
          />
        </Link>
      );
    }
  };

  const displayDownloadCanvas = useMemo(() => {
    if (langageJson) {
      return (
        <Link
          style={{ pointerEvents: !selectedMiniCanvas ? "none" : "" }}
          to={"/send-canvas"}
        >
          <CtaBtn
            label={
              process.env.REACT_APP_DISPLAY != "touchpad"
                ? langageJson.downloadEnd
                : langageJson?.cutCta
            }
            iconDark={nextDarkIcon}
            iconLight={nextDarkIcon}
            className={style.footerCta}
            disabled={!selectedMiniCanvas}
            iconPosition={"end"}
          />
        </Link>
      );
    }
  }, [langageJson, selectedMiniCanvas]);

  const displayFooter = useMemo(() => {
    return (
      <footer>
        {currentLocation != "/video" &&
          currentLocation != "/" &&
          !currentLocation.includes("mobile") &&
          currentLocation != "/congratulation" &&
          currentLocation != "/intro" &&
          currentLocation != "/mobileMessage" && (
            <Container fluid>
              <Row style={{ position: "relative" }}>
                <Col
                  md={12}
                  className={style.footerCtaContainer}
                  id="footerCtaContainer"
                >
                  {langageJson?.returnToHomePageCta && (
                    <Link to={"/"}>
                      <CtaBtn
                        label={langageJson?.returnToHomePageCta}
                        iconDark={homeIcon}
                        iconLight={homeIconOver}
                      />
                    </Link>
                  )}
                  {step.currentStep === 5 &&
                  currentLocation != "/select-canvas" &&
                  selectedLangage
                    ? displayLinkCta()
                    : currentLocation === "/select-canvas"
                    ? displayDownloadCanvas
                    : displayNextCta}
                </Col>
                {currentLocation !== "/video" && currentLocation !== "/" && (
                  <Button
                    onClick={(e) => {
                      creditModalHandleShow();
                    }}
                    className={style.creditCta}
                  >
                    <span>© CREDITS</span>
                  </Button>
                )}
              </Row>
            </Container>
          )}
        <Modal
          handleShow={handleShow}
          handleClose={handleClose}
          showModal={showModal}
          modalValues={modalValues}
        />
        <CreditModal
          handleShow={creditModalHandleShow}
          handleClose={creditModalHandleClose}
          showModal={showCreditModal}
        />
      </footer>
    );
  }, [langageJson]);

  return (
    <>
      {displayFooter}

      {currentLocation.includes("mobile") && (
        <>
          <Button
            onClick={(e) => {
              creditModalHandleShow();
            }}
            className={style.mobilecreditCta}
          >
            <span>© CREDITS</span>
          </Button>
          <Nav className={style.footerLink}>
            <Nav.Link
              href="https://www.parismusees.paris.fr/fr"
              target="_blank"
            >
              <img
                className={style.navItem}
                src={parisMuseeLogo}
                alt="logo Paris musée"
              />
            </Nav.Link>
            <Nav.Link href="https://www.mam.paris.fr" target="_blank">
              <img
                className={style.navItem}
                src={mamLogo}
                alt="logo musee d'art moderne"
              />
            </Nav.Link>
          </Nav>
        </>
      )}
    </>
  );
};

export default Footer;
