import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import homeIcon from "../../icons/cta-home-icon.svg";
import homeIconOver from "../../icons/home-icon-hover.svg";
import refreshDarkIcon from "../../icons/refresh-dark-icon.svg";
import refreshLightIcon from "../../icons/refresh-light-icon.svg";
import { Link } from "react-router-dom";

import CtaBtn from "../../Components/Btn/CtaBtn";

import style from "./EndScreen.module.scss";

const Index = () => {
  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));

  return (
    <Container className={style.endScreenContainer}>
      <Row>
        <Col md={7} className={style.titleContainer}>
          <h3>{langageJson.endConfirmation}</h3>
        </Col>

        <Col md={6}>
          <Link to={"/draw"}>
            <CtaBtn
              label={langageJson.startNewCreationCta}
              iconDark={refreshLightIcon}
              iconLight={refreshDarkIcon}
              className={style.redrawButton}
            />
          </Link>
        </Col>
        <Col md={6}>
          <Link to={"/"}>
            <CtaBtn
              label={langageJson.returnToHomePageCta}
              iconDark={homeIcon}
              iconLight={homeIconOver}
              isActive
            />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
