import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

// import Head from "next/head";
import { HelmetProvider } from "react-helmet-async";
import { purgeCookies } from "./lib/cookies";
import GDPRDetails from "./GDPRDetails";

import styles from "./GDPRManager.module.scss";
import GDPRMain from "./GDPRMain";
import { FBPIXEL_COOKIES, GA_COOKIES } from "./lib/references";
import TagManager from "react-gtm-module";
import { createPortal } from "react-dom";

const GDPRManager = ({ config = null, fbpSrc = "", editGDPR, setEditGDPR }) => {
  const initTagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
  };
  const [cookie, setCookie, removeCookie] = useCookies([
    // Default configuration
    ...FBPIXEL_COOKIES(),
    ...GA_COOKIES(config?.google || {}),
    "tarteaucitron",
    "CookiesConsent",
    "CONSENT",
    "youtube",
  ]);

  const [GDPRDisplay, setGDPRDisplay] = useState(false);
  const [cookieState, setCookieState] = useState(
    cookie?.CookiesConsent || {
      confirmed: false,
      gtag: false,
      facebookpixel: false,
    }
  );
  const [getPortalContainer, setGetPortalContainer] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const pathName = useLocation().pathname;
  const selectedLangage = localStorage.getItem("selectedLangage");

  useEffect(() => {
    if (cookie?.CookiesConsent?.gtag) {
      TagManager.initialize(initTagManagerArgs);
    }
  }, [cookie?.CookiesConsent?.gtag]);

  useEffect(() => {
    purgeCookies(cookieState, removeCookie, false);
  }, []);

  useEffect(() => {
    setIsLoaded(true);
  }, [pathName]);

  useEffect(() => {
    setGetPortalContainer(
      document.getElementById(
        pathName == "/draw" ? "footerCtaContainer" : "root"
      )
    );
    setIsLoaded(false);
  }, [isLoaded]);

  useEffect(() => {
    if (!cookie?.CookiesConsent?.confirmed) {
      setGDPRDisplay(true);
    }
  }, [cookie]);

  const fakeTAC = (cookiesObj) => {
    let tacStr = "";
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(cookiesObj)) {
      if (key !== "confirmed") tacStr += `!${key}=${value}`;
    }
    return tacStr;
  };

  const skipGDPR = () => {
    const skipState = {
      confirmed: true,
      gtag: false,
      facebookpixel: false,
    };
    setCookie("CookiesConsent", skipState, { path: "/" });

    purgeCookies(skipState, removeCookie, false);
    setGDPRDisplay(false);
  };

  const handleValidation = (cState = null) => {
    const spreadState = cState ? { ...cState } : { ...cookieState };

    setCookie(
      "CookiesConsent",
      { ...spreadState, confirmed: true },
      { path: "/" }
    );

    purgeCookies({ ...spreadState, confirmed: true }, removeCookie);
    setGDPRDisplay(false);
  };

  if (!cookie?.CookiesConsent) {
    setCookie(
      "CookiesConsent",
      {
        confirmed: false,
        gtag: false,
        facebookpixel: false,
      },
      { path: "/" }
    );
  }

  return (
    <>
      {(editGDPR || GDPRDisplay) && (
        <>
          {cookie?.CookiesConsent?.confirmed && (
            <HelmetProvider>
              {cookie?.CookiesConsent?.facebookpixel && (
                <script type="text/javascript" src={fbpSrc} />
              )}
            </HelmetProvider>
          )}
          {editGDPR && <div className={styles.GDPROverlay} />}

          {editGDPR ? (
            <div className={styles.GDPRWrapper}>
              <GDPRDetails
                cookieState={cookieState}
                setCookieState={setCookieState}
                handleValidation={handleValidation}
                color="#f8b936"
              />
            </div>
          ) : (
            <GDPRMain
              setEditGDPR={setEditGDPR}
              handleValidation={handleValidation}
              skipGDPR={skipGDPR}
            />
          )}
        </>
      )}
      {getPortalContainer &&
        createPortal(
          <button
            className={
              pathName == "/draw" ? styles.drawEditGDPRBtn : styles.editGDPRBtn
            }
            style={{
              display:
                pathName == "/video" || pathName == "/mobileHomepage"
                  ? "none"
                  : "block",
            }}
            onClick={() => setEditGDPR(true)}
          >
            {selectedLangage === "fr"
              ? "Gestion des cookies"
              : "Cookie management"}
          </button>,
          getPortalContainer
        )}
    </>
  );
};

GDPRManager.propTypes = {
  config: PropTypes.object,
  fbpSrc: PropTypes.string,
  gtagSrc: PropTypes.string,
  editGDPR: PropTypes.bool,
  setEditGDPR: PropTypes.func,
};

export default GDPRManager;
