import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";

import facebookIcon from "../../icons/facebook-icon.svg";
import twitterIcon from "../../icons/twitter-icon.svg";
import tripadvisorIcon from "../../icons/tripadvisor-icon.svg";
import instagramIcon from "../../icons/instagram-icon.svg";
import style from "./MobileSocials.module.scss";

const Index = () => {
  const selectedLangage = JSON.parse(localStorage.getItem("langageJson"));

  return (
    <Container className={style.mobileMainContainer}>
      <Row>
        <Col className={style.mobileTitleContainer} item sm={12}>
          <p>{selectedLangage.mobileSocialMessage1}</p>
        </Col>
        <Col className={style.mobileTitleContainer} sm={12}>
          <p>{selectedLangage.mobileSocialMessage2}</p>
        </Col>
        <Col sm={9}>
          <Nav className={style.mobileNavbarsocialsLink}>
            <Nav.Link href="https://www.facebook.com/MAMParis">
              <img src={facebookIcon} alt="lien facebook" />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/MAM">
              <img src={twitterIcon} alt="lien twitter" />
            </Nav.Link>
            <Nav.Link href="https://www.tripadvisor.fr/Attraction_Review-g187147-d188486-Reviews-Musee_d_Art_Moderne_de_Paris-Paris_Ile_de_France.html">
              <img src={tripadvisorIcon} alt="lien tripadvisor" />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/museedartmodernedeparis/">
              <img src={instagramIcon} alt="lien instragram" />
            </Nav.Link>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
