import React, { useContext } from "react";
import { StepperContext } from "../../Context/StepperProvider";
import style from "./Stepper.module.scss";

const Stepper = () => {
  const { currentStep } = useContext(StepperContext);
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));
  const display = process.env.REACT_APP_DISPLAY;

  const drawerSteps = [
    {
      description: langageJson.usingKraftShort,
      isFinish: false,
    },
    {
      description: langageJson.drawShort,
      isFinish: false,
    },

    {
      description: langageJson.undercoatShort,
      isFinish: false,
    },
    {
      description: langageJson.applyBackgroundShort,
      isFinish: false,
    },
    {
      description: langageJson.cutShort,
      isFinish: false,
    },
  ];
  return (
    <div
      className={`${
        (style.stepperContainer,
        display !== "touchpad" ? style.stepperContainerWebDisplay : "")
      }`}
    >
      <ul className={style.stepperLine}>
        {drawerSteps.map((step, key) => {
          if (step.description !== "") {
            return (
              <li key={key} className={style.step}>
                <span
                  className={
                    key + 1 === currentStep
                      ? style.stepRoundActive
                      : style.stepRound
                  }
                />
                {key + 1 === currentStep && (
                  <div className={style.details}>
                    <div className={style.stepContainer}>
                      <div className={style.stepNumber}>{`0${key + 1}`}</div>
                      <p className={style.stepDescription}>
                        {step.description}
                      </p>
                    </div>
                  </div>
                )}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default Stepper;
