import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CtaBtn from "../../Components/Btn/CtaBtn";
import { saveAs } from "file-saver";
import style from "./SendCanvas.module.scss";

const Index = () => {
  const selectedMiniCanvas = JSON.parse(
    localStorage.getItem("selectedMiniCanvas")
  );
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));
  const display = process.env.REACT_APP_DISPLAY;

  const download = () => {
    const selectedMiniCanvas = JSON.parse(
      localStorage.getItem("selectedMiniCanvas")
    );
    let url = `${selectedMiniCanvas.picture}`;
    saveAs(url, "mini-peinture");
  };

  return (
    <Container fluid className={style.pictureMainContainer}>
      <Row>
        <Col className={style.pictureContainer} md={12}>
          <img
            className={style.picture}
            src={selectedMiniCanvas.picture}
            alt="previewPicture"
          />
          {display != "touchpad" && (
            <div className={style.downloadContainer}>
              {/* <h3>{langageJson.downloadEnd}</h3> */}
              <CtaBtn
                label={langageJson.endCtaWeb}
                isActive={true}
                onClick={() => {
                  download();
                }}
                className={style.sideMenuFormBtn}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
