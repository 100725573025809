import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import style from "../CtaBtn/CtaBtn.module.scss";

const CircularBtn = (props) => {
  const { onClick, isActive, iconLight, iconDark, className, label } = {
    ...props,
  };
  const [mouseIsOver, setMouseIsOver] = useState(false);

  return (
    <Button
      className={`${style.circularBtn} ${
        isActive || mouseIsOver ? style.isActive : ""
      }            
      ${className}
      `}
      onMouseEnter={(e) => setMouseIsOver(true)}
      onMouseLeave={(e) => setMouseIsOver(false)}
      onClick={onClick}
    >
      <img src={mouseIsOver || isActive ? iconLight : iconDark} />
      {label && (
        <span className={style.circularBtnLabel}>{label.toUpperCase()}</span>
      )}
    </Button>
  );
};

CircularBtn.propTypes = {
  iconDark: PropTypes.string,
  iconLight: PropTypes.string,
  iconPosition: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default CircularBtn;
