import React from "react";
import PropTypes from "prop-types";
import CustomText from "../CustomText";
import styles from "./Caption.module.scss";

const Caption = ({ content, figcaption = false }) => {
  return (
    <CustomText
      type={figcaption ? "figcaption" : "p"}
      className={styles.caption}
    >
      {content}
    </CustomText>
  );
};

Caption.propTypes = {
  content: PropTypes.string,
  figcaption: PropTypes.bool,
};

export default Caption;
