import React, { createContext, useState, useEffect } from "react";

import PropTypes from "prop-types";

export const MiniCanvasContext = createContext();

const MiniCanvasProvider = ({ children }) => {
  const [miniCanvasList, setMiniCanvasList] = useState([]);
  const [selectedMiniCanvas, setSelectedMiniCanvas] = useState();

  useEffect(() => {
    const value = localStorage.getItem("selectedMiniCanvas");
    if (value !== "undefined") {
      setSelectedMiniCanvas(
        JSON.parse(localStorage.getItem("selectedMiniCanvas"))
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "selectedMiniCanvas",
      JSON.stringify(selectedMiniCanvas)
    );
  }, [selectedMiniCanvas]);

  return (
    <MiniCanvasContext.Provider
      value={{
        miniCanvasList,
        setMiniCanvasList,
        selectedMiniCanvas,
        setSelectedMiniCanvas,
      }}
    >
      {children}
    </MiniCanvasContext.Provider>
  );
};

MiniCanvasProvider.propTypes = {
  children: PropTypes.any,
};

export default MiniCanvasProvider;
