import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import CtaBtn from "../../Components/Btn/CtaBtn";
// import CircularBtn from "Components/Btn/CircularBtn";
import style from "./Home.module.scss";

const Home = () => {
  const pathName = useLocation().pathname;

  useEffect(() => {
    if (pathName) {
      sessionStorage.setItem("pathName", pathName);
    }
  }, [pathName]);

  useEffect(() => {
    sessionStorage.setItem("langage", null);
  }, []);

  const setLangage = (selectedLangage) => {
    sessionStorage.setItem("langage", selectedLangage);
  };

  return (
    <Container className={`${style.homeTitleContainer}`}>
      <Row>
        <Col sm={12}>
          <h2 className={`${style.homeTitle}`}>
            Maintenant,&nbsp;
            <span className={`${style.spanTitle}`}>à vous de jouer !</span>
          </h2>
        </Col>
        <Col className={`${style.homeCTA}`} sm={8}>
          <Link to={"/select-langage"}>
            <CtaBtn label="COMMENCER MA CREATION" isActive={true} />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
