import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import mailTemplate from "../templates/email/email";

import PropTypes from "prop-types";

export const EmailContext = createContext();

const EmailProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [submitEmail, setSubmitEmail] = useState(false);

  const currentLocation = useLocation().pathname;

  useEffect(() => {
    setEmail("");
  }, [currentLocation]);

  useEffect(() => {
    if (submitEmail) {
      const selectedCanvas = JSON.parse(
        localStorage.getItem("selectedMiniCanvas")
      );
      const selectedLangage = localStorage.getItem("selectedLangage");

      const sendEmailFunc = async () => {
        const res = await window.api.sendMail({
          emailAddress: email,
          emailTemplate: mailTemplate(selectedLangage),
          emailAttachments: selectedCanvas.picture,
          subject:
            selectedLangage === "fr"
              ? "Ma mini-peinture - Anna-Eva Bergman au MAM Paris"
              : "My mini-painting - Anna-Eva Bergman at the MAM Paris",
          filename:
            selectedLangage === "fr"
              ? "mini-peinture.jpg"
              : "mini-painting.jpg",
        });
      };
      sendEmailFunc();
    }
  }, [submitEmail]);

  return (
    <EmailContext.Provider
      value={{
        email,
        setEmail,
        sendEmail,
        setSendEmail,
        submitEmail,
        setSubmitEmail,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
};

EmailProvider.propTypes = {
  children: PropTypes.any,
};

export default EmailProvider;
