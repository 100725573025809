import React, { useMemo, useContext } from "react";
import screenSaverVideo from "../../videos/ecran_de_ veille_Bergman_V2.mp4";
import { ScreenSaverContext } from "../../Context/ScreenSaverProvider";

import style from "./ScreenSaver.module.scss";

const Index = () => {
  const { setScreenSaverIsRunning, displayvideo } =
    useContext(ScreenSaverContext);
  const display = process.env.REACT_APP_DISPLAY;
  const video = useMemo(() => {
    if (displayvideo && display === "touchpad") {
      setScreenSaverIsRunning(true);
      return (
        <video className={style.videoPlayer} autoPlay loop muted>
          <source
            src={screenSaverVideo}
            type="video/mp4"
            style={{ borderRadius: "0", height: "100vh" }}
          />
        </video>
      );
    }
  }, [displayvideo]);

  return <div className={style.screenSaverVideoContainer}>{video}</div>;
};

export default Index;
