/* eslint-disable prefer-destructuring */
import React from "react";
import PropTypes from "prop-types";

import Caption from "../Caption";
import styles from "./BasicImage.module.scss";

const BasicImage = ({
  src,
  caption,
  alt,
  className,
  width,
  height,
  objMeta,
  responsive = true,
  quality = 0,
}) => {
  if (objMeta) {
    width = objMeta.width;
    height = objMeta.height;
    alt = objMeta.alt;
  }
  const needFill = !width || !height;
  let classes = className || "";
  if (needFill) {
    classes += " " + styles.fill;
  }
  if (responsive) {
    classes += " " + styles.responsive;
  }
  return (
    <>
      {caption ? (
        <figure role="figure" aria-label={caption}>
          <img
            alt={alt}
            src={src}
            width={width}
            height={height}
            {...(needFill && { fill: true })}
            className={classes}
          />
          {caption && <Caption content={caption} figcaption />}
        </figure>
      ) : (
        <img
          alt={alt}
          src={src}
          width={width}
          height={height}
          {...(needFill && { fill: true })}
          className={classes}
        />
      )}
    </>
  );
};

export default BasicImage;

BasicImage.propTypes = {
  alt: PropTypes.string,
  caption: PropTypes.string,
  className: PropTypes.any,
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  responsive: PropTypes.bool,
  quality: PropTypes.number,
  objMeta: PropTypes.any,
};
