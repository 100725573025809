import React from "react";
import { Link } from "react-router-dom";

import CtaBtn from "../../Components/Btn/CtaBtn";
import style from "./Intro.module.scss";

const index = () => {
  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));

  return (
    <div className={style.introTitleContainer}>
      <h3 className={style.introTitle}>{langageJson.nowItIsYourTurn}</h3>
      <Link to={"/draw"}>
        <CtaBtn label={langageJson.createMyMiniPaintings} isActive={true} />
      </Link>
    </div>
  );
};

export default index;
