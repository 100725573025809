import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import fr from "../Utils/localisation/fr.json";
import en from "../Utils/localisation/en.json";

import PropTypes from "prop-types";

export const LocalisationContext = createContext();

const LocalisationProvider = ({ children }) => {
  const [selectedLangage, setSelectedLangage] = useState(null);
  const [langageJson, setLangageJson] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (selectedLangage) {
      if (selectedLangage === "fr") {
        setLangageJson(fr);
        localStorage.setItem("langageJson", JSON.stringify(fr));
        localStorage.setItem("selectedLangage", selectedLangage);
      } else {
        setLangageJson(en);
        localStorage.setItem("langageJson", JSON.stringify(en));
        localStorage.setItem("selectedLangage", selectedLangage);
      }
    }
  }, [selectedLangage, pathname]);

  return (
    <LocalisationContext.Provider
      value={{
        selectedLangage,
        setSelectedLangage,
        langageJson,
        setLangageJson,
      }}
    >
      {children}
    </LocalisationContext.Provider>
  );
};

LocalisationProvider.propTypes = {
  children: PropTypes.any,
};

export default LocalisationProvider;
