import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const StepperContext = createContext();

const StepperProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [finishedSteps, setFinishedSteps] = useState([]);

  return (
    <StepperContext.Provider
      value={{ currentStep, setCurrentStep, finishedSteps, setFinishedSteps }}
    >
      {children}
    </StepperContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.any,
};

export default StepperProvider;
