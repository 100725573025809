import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { LazyBrush } from "lazy-brush";
import useAnimationFrame from "Utils/hooks/useAnimationFrame";
import MiniCanvas from "./MiniCanvas";
import UndercoatCanvas from "./UndercoatCanvas";
import useRefState from "Utils/hooks/useRefState";
import { BrushStroke } from "Utils/brushes/BrushStroke";
import backgroundCanvasHq from "../../pictures/bergman-papierHq.png";
import backgroundCanvas from "../../pictures/bergman-papier_lq.jpeg";

import tapePicture from "../../pictures/tape.png";
import horizontalTape from "../../pictures/horizontalTape.png";
import cardBoard from "../../pictures/cardBoard.jpg";

import goldPicture from "../../background/gold-background.jpg";
import silverPicture from "../../background/silver-background.jpg";

import goldPictureHQ from "../../background/gold-background.png";
import silverPictureHQ from "../../background/silver-background.png";
import { canvasToPng } from "Utils/utils";
import { MiniCanvasContext } from "../../Context/MiniCanvasProvider";
import { GlobalScrollContext } from "../../Context/GlobalScrollProvider";

import style from "./Drawer.module.scss";
import { createRef } from "react";
import { SimpleBrush } from "Utils/brushes/SimpleBrush";
import { BrushSoft } from "Utils/brushes/BrushSoft";
import { BrushSmall } from "Utils/brushes/BrushSmall";

const InteractiveCanvas = ({
  dimensions,
  selectedColor,
  resetCanvas,
  setResetCanvas,
  setSnapCanvas,
  getSnapCanvas,
  selectedBrush,
  step,
  contextUndercoat,
  endStep,
  modalOpen,
  getSnapStepCanvas,
  setSnapStepCanvas,
  getCanvasNeedUndo,
  setCanvasNeedUndo,
  setSnapUndercoatCanvas,
  snapUndercoatCanvas,
  getSnapStepUndercoatCanvas,
  setUndercoat,
  getUndercoat,
  setUnderCoatIsApply,
  scale,
}) => {
  const LAZY_RADIUS = 10 * scale;
  const TAPE_SET_DISTANCE_PERCENT = 0.1;
  const TAPE_MOVE_TOLERANCE =
    dimensions().width < 1200 ? 180 * scale : 80 * scale;
  const TAPE_AREA_TOLERANCE_MULTIPLIER = 2 * scale;

  const display = process.env.REACT_APP_DISPLAY;

  const [isReady, setIsReady] = useState(false);
  const [savedCanvas, setSavedCanvas, getSavedCanvas] = useRefState();
  const [currentColor, setCurrentColor, getCurrentColor] = useRefState();
  const [currentStep, setCurrentStep, getCurrentStep] = useRefState();
  const [tapes, setTapes, getTapes] = useRefState([]);
  const miniCanvasListContext = useContext(MiniCanvasContext);
  const { setDisplayScrollBar } = useContext(GlobalScrollContext);

  const [brushWidth, setBrushWidth, getBrushWidth] = useRefState();

  const [brush, setBrush, getBrush] = useRefState();
  const canvasContainer = useRef(null);
  const mainCanvas = useRef();
  const tempCanvas = useRef();
  const undercoatCanvas = useRef();
  const undercoatOverlayCanvas = useRef();
  const miniCanvasRefs = useRef([]);
  const tapeWidth = 40;
  const { pathname } = useLocation();

  const [mousePosition, setMousePosition, getMousePosition] = useRefState({
    x: 0,
    y: 0,
  });
  const [mouseStartPos, setMouseStartPos, getMouseStartPos] = useRefState(null);
  const [currentTapesTested, setCurrentTapesTested] = useState([]);
  const [isDrawing, setIsDrawing, getIsDrawing] = useRefState(false);
  const lazy = new LazyBrush({
    enabled: true,
    radius: LAZY_RADIUS,
  });

  const handleMouseMove = (ev) => {
    const clientX = ev.clientX ? ev.clientX : ev.changedTouches[0].clientX;
    const clientY = ev.clientX ? ev.clientY : ev.changedTouches[0].clientY;

    if (!canvasContainer.current) return;
    const rect = canvasContainer.current.getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    setMousePosition({ x, y });
    if (
      x >= 0 &&
      x <= rect.width &&
      y >= 0 &&
      y <= rect.height &&
      (ev.changedTouches || (ev.buttons & 1) === 1) &&
      !getMouseStartPos() &&
      (getCurrentStep() === 1 || getCurrentStep() === 5) &&
      !modalOpen()
    ) {
      setMouseStartPos({ x, y });
      setCurrentTapesTested(getTargetTapes());
      setIsDrawing(true);
    }
  };
  const handleMouseDown = (ev) => {
    setDisplayScrollBar(true);
    const clientX = ev.clientX ? ev.clientX : ev.changedTouches[0].clientX;
    const clientY = ev.clientX ? ev.clientY : ev.changedTouches[0].clientY;

    if (!canvasContainer.current) return;
    const rect = canvasContainer.current.getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    setMousePosition({ x, y });
    if ((getCurrentStep() === 1 || getCurrentStep() === 5) && !modalOpen()) {
      setMouseStartPos({ x, y });
      setCurrentTapesTested(getTargetTapes());
    }
    setIsDrawing(true);
    if (getCurrentStep() === 2) {
      setBrush(new BrushStroke(20 * scale, "#000000"));
      step.setFinishedSteps((oldStepsList) => {
        const newStepList = [...oldStepsList];
        if (!newStepList.includes(getCurrentStep())) {
          newStepList.push(getCurrentStep());
        }

        return newStepList;
      });
    } else if (getCurrentStep() === 3) {
      setBrush(new SimpleBrush(20 * scale, "#ea4335"));
      step.setFinishedSteps((oldStepsList) => {
        const newStepList = [...oldStepsList];
        if (!newStepList.includes(getCurrentStep())) {
          newStepList.push(getCurrentStep());
        }

        return newStepList;
      });
    } else {
      let chosenBrush;
      if (getBrushWidth() === 250) {
        chosenBrush = new BrushSoft(getBrushWidth(), getCurrentColor());
      } else if (getBrushWidth() === 50) {
        chosenBrush = new BrushSmall(getBrushWidth(), getCurrentColor());
      } else {
        chosenBrush = new BrushStroke(getBrushWidth(), getCurrentColor());
      }

      setBrush(chosenBrush);
      if (getCurrentStep() === 4) {
        step.setFinishedSteps((oldStepsList) => {
          const newStepList = [...oldStepsList];
          if (!newStepList.includes(getCurrentStep())) {
            newStepList.push(getCurrentStep());
          }

          return newStepList;
        });
      }
    }
  };

  const handleMouseUp = () => {
    setMouseStartPos(null);
    setIsDrawing(false);
    setDisplayScrollBar(false);
  };

  const calculatedTapeWidth = () => {
    return (dimensions().width * tapeWidth) / 1090;
  };
  const getTargetTapes = () => {
    const tapes = getTapes();
    const miniWidth =
      (dimensions().width / scale - 2 * calculatedTapeWidth()) / 3;
    const miniHeight =
      (dimensions().height / scale - 2 * calculatedTapeWidth()) / 3;

    const targetTapes = [];
    if (
      checkAreaTapeClicked(
        dimensions().width / 2,
        miniHeight + calculatedTapeWidth(),
        dimensions().width,
        calculatedTapeWidth() * TAPE_AREA_TOLERANCE_MULTIPLIER
      ) &&
      !tapes[0]
    ) {
      targetTapes.push(0);
    }
    if (
      checkAreaTapeClicked(
        dimensions().width / 2,
        miniHeight * 2 + calculatedTapeWidth() * 1.5,
        dimensions().width,
        calculatedTapeWidth() * TAPE_AREA_TOLERANCE_MULTIPLIER
      ) &&
      !tapes[1]
    ) {
      targetTapes.push(1);
    }
    if (
      checkAreaTapeClicked(
        miniWidth + calculatedTapeWidth(),
        dimensions().height / 2,
        calculatedTapeWidth() * TAPE_AREA_TOLERANCE_MULTIPLIER,
        dimensions().height
      ) &&
      !tapes[2]
    ) {
      targetTapes.push(2);
    }
    if (
      checkAreaTapeClicked(
        miniWidth * 2 + calculatedTapeWidth() * 1.5,
        dimensions().height / 2,
        calculatedTapeWidth() * TAPE_AREA_TOLERANCE_MULTIPLIER,
        dimensions().height
      ) &&
      !tapes[3]
    ) {
      targetTapes.push(3);
    }
    return targetTapes;
  };

  const checkAreaTapeClicked = (x, y, width, height) => {
    const mouse = getMousePosition();
    return (
      Math.abs(x - mouse.x) < width / 2 && Math.abs(y - mouse.y) < height / 2
    );
  };

  const doTapeStuff = (i) => {
    const miniWidth = (dimensions().width - 2 * calculatedTapeWidth()) / 3;
    const miniHeight = (dimensions().height - 2 * calculatedTapeWidth()) / 3;
    const tapes = [...getTapes()];
    tapes[i] = true;
    setTapes(tapes);
    const ctx = mainCanvas.current.getContext("2d");
    if (getCurrentStep() === 1) {
      var tapeImg = new Image();
      tapeImg.onload = function () {
        ctx.drawImage(
          tapeImg,
          i < 2 ? 0 : miniWidth * (i - 1) + calculatedTapeWidth() * (i - 2),
          i < 2 ? miniHeight * (i + 1) + calculatedTapeWidth() * i : 0,
          i < 2 ? dimensions().width : calculatedTapeWidth(),
          i < 2 ? calculatedTapeWidth() : dimensions().height
        );
      };
      tapeImg.src = i < 2 ? horizontalTape : tapePicture;
    } else {
      var cardBoardImg = new Image();

      cardBoardImg.onload = function () {
        ctx.drawImage(
          cardBoardImg,
          i < 2 ? 0 : miniWidth * (i - 1) + calculatedTapeWidth() * (i - 2),
          i < 2 ? miniHeight * (i + 1) + calculatedTapeWidth() * i : 0,
          i < 2 ? dimensions().width : calculatedTapeWidth(),
          i < 2 ? calculatedTapeWidth() : dimensions().height
        );
      };
      cardBoardImg.src = cardBoard;
    }
    ctx.globalCompositeOperation = "source-over";
    if (tapes.every((item) => item)) {
      endStep(getCurrentStep);
      if (getCurrentStep() === 5 && mainCanvas.current) {
        drawOnMiniCanvas();
        for (let i = 0; i < miniCanvasRefs.current.length; i++) {
          miniCanvasListContext.setMiniCanvasList((oldList) => {
            const newList = [...oldList];
            newList.push({
              className: "miniCanvas" + i,
              picture: canvasToPng(miniCanvasRefs.current[i]),
            });
            return Array.from(new Set(newList.map((a) => a.className))).map(
              (className) => {
                return newList.find((a) => a.className === className);
              }
            );
          });
        }
      }
    }
  };

  const startDrawing = () => {
    const canvasSnap = document.createElement("canvas");
    canvasSnap.width = dimensions().width;
    canvasSnap.height = dimensions().height;
    var savedCtx = canvasSnap.getContext("2d");
    savedCtx.drawImage(
      mainCanvas.current,
      0,
      0,
      dimensions().width,
      dimensions().height
    );

    setSavedCanvas(canvasSnap);
  };

  const endDrawing = () => {
    const canvasSnap = document.createElement("canvas");
    canvasSnap.width = dimensions().width;
    canvasSnap.height = dimensions().height;
    var savedCtx = canvasSnap.getContext("2d");
    savedCtx.drawImage(
      mainCanvas.current,
      0,
      0,
      dimensions().width,
      dimensions().height
    );
    setSnapCanvas(canvasSnap);

    if (step.currentStep === 3) {
      const undercoatCanvasSnap = document.createElement("canvas");
      undercoatCanvasSnap.width = dimensions().width;
      undercoatCanvasSnap.height = dimensions().height;
      var savedUndercoatCtx = undercoatCanvasSnap.getContext("2d");

      savedUndercoatCtx.drawImage(
        undercoatCanvas.current,
        0,
        0,
        dimensions().width,
        dimensions().height
      );
      setSnapUndercoatCanvas(undercoatCanvasSnap);
    }
    clearCanvas(tempCanvas);
  };

  const clearCanvas = (canvasRef) => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, dimensions().width, dimensions().height);
  };

  const drawLoop = () => {
    if (!lazy || !mainCanvas.current || getCurrentStep() === 1) return;
    lazy.update({ x: getMousePosition().x, y: getMousePosition().y });
    let currentPos = lazy.getBrushCoordinates();
    currentPos.x = currentPos.x * scale;
    currentPos.y = currentPos.y * scale;

    if (!getIsDrawing()) return;
    if (getCurrentStep() === 2 || getCurrentStep() === 4) {
      // Draw on temp canvas
      const tempsCtx = tempCanvas.current.getContext("2d");
      const currentBrushStroke = getBrush();

      currentBrushStroke.draw(tempsCtx, currentPos);

      // Apply on main canvas
      applyOnMainCanvas(tempCanvas, "draw");
    } else if (getCurrentStep() === 3 && !getUndercoat()) {
      const undercoatCtx = undercoatCanvas.current.getContext("2d");

      const currentBrushStroke = getBrush();
      currentBrushStroke.draw(undercoatCtx, currentPos);
    }
  };

  const drawOnMiniCanvas = () => {
    const miniWidth = (dimensions().width - calculatedTapeWidth() * 2) / 3;
    const miniHeight = (dimensions().height - calculatedTapeWidth() * 2) / 3;
    for (let i = 0; i < miniCanvasRefs.current.length; i++) {
      applyOnMiniCanvas(
        miniCanvasRefs.current[i],
        "draw",
        (miniWidth + calculatedTapeWidth()) * Math.floor(i % 3),
        (miniHeight + calculatedTapeWidth()) * Math.floor(i / 3),
        miniWidth,
        miniHeight
      );
    }
  };

  const applyOnMiniCanvas = (
    miniCanvas,
    ctxName,
    sx,
    sy,
    imageHeight,
    imageWidth
  ) => {
    const ctx = miniCanvas["current"].getContext("2d");
    ctx.drawImage(
      mainCanvas.current,
      sx,
      sy,
      imageHeight,
      imageWidth,
      0,
      0,
      imageHeight,
      imageWidth
    );
  };

  const applyOnMainCanvas = (applyContext, ctxName) => {
    const ctx = mainCanvas.current.getContext("2d");
    ctx.globalCompositeOperation = "source-over";
    if (getSavedCanvas()) {
      ctx.drawImage(getSavedCanvas(), 0, 0);
      switch (ctxName) {
        case "draw":
          ctx.globalCompositeOperation = "multiply";
          break;
        case "undercoat":
          ctx.globalCompositeOperation = "source-over";
          break;
        default:
          ctx.globalCompositeOperation = "source-over";
          break;
      }
      ctx.drawImage(applyContext.current, 0, 0);
    }
  };

  useAnimationFrame(drawLoop);
  useEffect(() => {
    setTimeout(() => {
      const newSnapStepCanvas = [...getSnapStepCanvas()];
      const shadowCanvas = document.createElement("canvas");
      shadowCanvas.width = dimensions().width;
      shadowCanvas.height = dimensions().height;
      var shadowSavedCtx = shadowCanvas.getContext("2d");
      shadowSavedCtx.drawImage(
        mainCanvas.current,
        0,
        0,
        dimensions().width,
        dimensions().height
      );
      setSnapStepCanvas([...newSnapStepCanvas, shadowCanvas]);
    }, 200);
  }, [getTapes()]);

  useEffect(() => {
    if (getCanvasNeedUndo()) {
      const ctx = mainCanvas.current.getContext("2d");

      ctx.globalCompositeOperation = "source-over";
      ctx.clearRect(0, 0, dimensions().width, dimensions().height);

      ctx.drawImage(
        getSnapStepCanvas()[getSnapStepCanvas().length - 1],
        0,
        0,
        dimensions().width,
        dimensions().height
      );

      if (step.currentStep === 3) {
        startDrawing();

        const undercoatCanvasCtx = undercoatCanvas.current.getContext("2d");
        undercoatCanvasCtx.clearRect(
          0,
          0,
          dimensions().width,
          dimensions().height
        );

        undercoatCanvasCtx.globalCompositeOperation = "source-over";
        undercoatCanvasCtx.drawImage(
          getSnapStepUndercoatCanvas()[getSnapStepUndercoatCanvas().length - 1],
          0,
          0,
          dimensions().width,
          dimensions().height
        );
        if (contextUndercoat.selectedColor) {
          contextUndercoat.setSelectedColor(null);
        }
      }
      setCanvasNeedUndo(false);
      setUnderCoatIsApply(false);
    }
  }, [getCanvasNeedUndo()]);

  useEffect(() => {
    setBrushWidth(selectedBrush);
  }, [selectedBrush]);

  useEffect(() => {
    setCurrentStep(step.currentStep);
  }, [step]);

  useEffect(() => {
    if (currentStep === 5) {
      const ctx = mainCanvas.current.getContext("2d");
      const shadowCanvas = document.createElement("canvas");
      shadowCanvas.width = dimensions().width;
      shadowCanvas.height = dimensions().height;
      var shadowSavedCtx = shadowCanvas.getContext("2d");

      drawDottedLine(ctx);
      drawDottedLine(shadowSavedCtx);
    }

    const newCurrentCanvas = { ...mainCanvas };
    setSnapStepCanvas([]);
    setSnapUndercoatCanvas([]);
    const canvasSnap = document.createElement("canvas");
    canvasSnap.width = dimensions().width;
    canvasSnap.height = dimensions().height;
    var savedCtx = canvasSnap.getContext("2d");

    savedCtx.drawImage(mainCanvas.current, 0, 0);
    setSnapCanvas(canvasSnap);

    if (step.currentStep === 3) {
      const undercoatCanvasSnap = document.createElement("canvas");
      undercoatCanvasSnap.width = dimensions().width;
      undercoatCanvasSnap.height = dimensions().height;
      var savedUndercoatCtx = undercoatCanvasSnap.getContext("2d");

      savedUndercoatCtx.drawImage(undercoatCanvas.current, 0, 0);
      setSnapUndercoatCanvas(undercoatCanvasSnap);
    }
  }, [currentStep]);

  useEffect(() => {
    if (contextUndercoat.selectedColor) {
      setUndercoat(contextUndercoat.selectedColor);
    } else {
      setUndercoat();
      clearCanvas(undercoatOverlayCanvas);
    }
  }, [contextUndercoat]);

  useEffect(() => {
    if (getSnapStepUndercoatCanvas().length === 1) {
      setUndercoat();
    }
  }, [getSnapStepUndercoatCanvas()]);

  useEffect(() => {
    if (getUndercoat()) {
      let overlay = new Image();

      const gold = display === "touchpad" ? goldPictureHQ : goldPicture;
      const silver = display === "touchpad" ? silverPictureHQ : silverPicture;

      overlay.src = getUndercoat() === "gold" ? gold : silver;
      overlay.onload = function () {
        const undercoatCurrent = undercoatCanvas.current;
        let canvasSnapCtx = undercoatOverlayCanvas.current.getContext("2d");

        canvasSnapCtx.drawImage(
          overlay,
          0,
          0,
          dimensions().width,
          dimensions().height
        );
        canvasSnapCtx.globalCompositeOperation = "source-in";

        canvasSnapCtx.globalCompositeOperation = "destination-atop";

        canvasSnapCtx.drawImage(undercoatCurrent, 0, 0);
        applyOnMainCanvas(undercoatOverlayCanvas, "undercoat");

        endDrawing();
      };
    }
  }, [getUndercoat()]);

  useEffect(() => {
    if (getSavedCanvas()) {
      const ctx = mainCanvas.current.getContext("2d");
      ctx.globalCompositeOperation = "source-over";

      getSnapStepCanvas().forEach((canvas) => {
        const canvasSnap = document.createElement("canvas");
        canvasSnap.width = dimensions().width;
        canvasSnap.height = dimensions().height;
        var savedCtx = canvasSnap.getContext("2d");
        savedCtx.drawImage(
          canvasSnap,
          0,
          0,
          dimensions().width,
          dimensions().height
        );
        canvas = canvasSnap;
        return canvas;
      });
      ctx.drawImage(
        getSnapStepCanvas()[getSnapStepCanvas().length - 1],
        0,
        0,
        dimensions().width,
        dimensions().height
      );
      setSavedCanvas(getSnapCanvas());

      if (getSnapStepUndercoatCanvas().length > 0) {
        const undercoatCtx = undercoatCanvas.current.getContext("2d");
        undercoatCtx.globalCompositeOperation = "source-over";

        getSnapStepUndercoatCanvas().forEach((canvas) => {
          const canvasSnap = document.createElement("canvas");
          canvasSnap.width = dimensions().width;
          canvasSnap.height = dimensions().height;
          var savedCtx = canvasSnap.getContext("2d");
          savedCtx.drawImage(
            canvasSnap,
            0,
            0,
            dimensions().width,
            dimensions().height
          );
          canvas = canvasSnap;
          return canvas;
        });
        undercoatCtx.drawImage(
          getSnapStepUndercoatCanvas()[getSnapStepUndercoatCanvas().length - 1],
          0,
          0,
          dimensions().width,
          dimensions().height
        );
      }
    } else {
      initCanvas();
    }
  }, [dimensions()]);

  useEffect(() => {
    setCurrentColor(selectedColor);
  }, [selectedColor]);

  useEffect(() => {
    if (!isReady) return;
    if (isDrawing) {
      startDrawing();
    } else {
      endDrawing();
    }
  }, [isDrawing]);

  useEffect(() => {
    if (resetCanvas) {
      initCanvas();
      setSavedCanvas();
      step.setFinishedSteps([]);
      setCurrentStep(step.currentStep);
      setResetCanvas(false);
      generateMiniCanvas();
      miniCanvasRefs.current.forEach((miniCanvasRefs) => {
        clearCanvas(miniCanvasRefs);
      });
    }
  }, [resetCanvas]);

  useEffect(() => {
    generateMiniCanvas();
  }, []);

  useEffect(() => {
    if (
      (getCurrentStep() === 1 || getCurrentStep() === 5) &&
      !modalOpen() &&
      isDrawing
    ) {
      const targetTapes = [...currentTapesTested];
      if (
        Math.abs(mousePosition.x - mouseStartPos.x) >
        TAPE_MOVE_TOLERANCE / scale
      ) {
        if (targetTapes.indexOf(2) !== -1) {
          targetTapes.splice(targetTapes.indexOf(2));
        }
        if (targetTapes.indexOf(3) !== -1) {
          targetTapes.splice(targetTapes.indexOf(3));
        }
      }
      if (
        Math.abs(mousePosition.y - mouseStartPos.y) >
        TAPE_MOVE_TOLERANCE / scale
      ) {
        if (targetTapes.indexOf(0) !== -1) {
          targetTapes.splice(targetTapes.indexOf(0));
        }
        if (targetTapes.indexOf(1) !== -1) {
          targetTapes.splice(targetTapes.indexOf(1));
        }
      }
      setCurrentTapesTested(targetTapes);
      if (
        mousePosition.x < 0 ||
        mousePosition.x > dimensions().width ||
        mousePosition.y < 0 ||
        mousePosition.y > dimensions().height ||
        targetTapes.length === 0
      ) {
        setIsDrawing(false);
        return;
      }
      if (
        Math.abs(mousePosition.x - mouseStartPos.x) >
        (dimensions().width * TAPE_SET_DISTANCE_PERCENT) / scale
      ) {
        if (targetTapes.indexOf(0) !== -1) {
          doTapeStuff(0);
          setIsDrawing(false);
        } else if (targetTapes.indexOf(1) !== -1) {
          doTapeStuff(1);
          setIsDrawing(false);
        }
      } else if (
        Math.abs(mousePosition.y - mouseStartPos.y) >
        (dimensions().height * TAPE_SET_DISTANCE_PERCENT) / scale
      ) {
        if (targetTapes.indexOf(2) !== -1) {
          doTapeStuff(2);
          setIsDrawing(false);
        } else if (targetTapes.indexOf(3) !== -1) {
          doTapeStuff(3);
          setIsDrawing(false);
        }
      }
    }
  }, [mousePosition, dimensions()]);

  const generateMiniCanvas = () => {
    miniCanvasRefs.current = Array(9)
      .fill()
      .map((_, i) => miniCanvasRefs.current[i] || createRef());
    miniCanvasListContext.setMiniCanvasList([]);
    miniCanvasListContext.setSelectedMiniCanvas("");
  };

  const drawDottedLine = (ctx) => {
    ctx.setLineDash([5 * scale, 3 * scale]);
    ctx.strokeStyle = "#000000";
    ctx.lineWidth = scale;
    ctx.beginPath();
    const offset = 10;
    for (let i = 0; i < 4; i++) {
      const miniWidth = (dimensions().width - 2 * calculatedTapeWidth()) / 3;
      const miniHeight = (dimensions().height - 2 * calculatedTapeWidth()) / 3;
      ctx.moveTo(
        i < 2
          ? miniWidth * (i + 1) +
              calculatedTapeWidth() * i +
              calculatedTapeWidth() / 2
          : 0 + offset,
        i < 2
          ? 0 + offset
          : miniHeight * (i - 1) +
              calculatedTapeWidth() * (i - 2) +
              calculatedTapeWidth() / 2
      );
      ctx.lineTo(
        i < 2
          ? miniWidth * (i + 1) +
              calculatedTapeWidth() * i +
              calculatedTapeWidth() / 2
          : dimensions().width - offset,
        i < 2
          ? dimensions().height - offset
          : miniHeight * (i - 1) +
              calculatedTapeWidth() * (i - 2) +
              calculatedTapeWidth() / 2
      );
      ctx.stroke();
    }

    setTapes([false, false, false, false]);
  };

  const initCanvas = () => {
    clearCanvas(mainCanvas);
    clearCanvas(undercoatCanvas);
    clearCanvas(undercoatOverlayCanvas);
    clearCanvas(tempCanvas);
    //dom loaded
    const ctx = mainCanvas.current.getContext("2d");
    const shadowCanvas = document.createElement("canvas");
    shadowCanvas.width = dimensions().width;
    shadowCanvas.height = dimensions().height;
    var savedCtx = shadowCanvas.getContext("2d");

    ctx.rect(0, 0, dimensions().width, dimensions().height);
    ctx.fillStyle = "#FFF";
    ctx.fill();
    var img = new Image(dimensions().width, dimensions().height);

    img.onload = function () {
      ctx.drawImage(img, 0, 0, dimensions().width, dimensions().height);
      savedCtx.drawImage(img, 0, 0, dimensions().width, dimensions().height);

      drawDottedLine(ctx);
      drawDottedLine(savedCtx);
    };
    img.src = display === "touchpad" ? backgroundCanvasHq : backgroundCanvas;
    shadowCanvas.src =
      display === "touchpad" ? backgroundCanvasHq : backgroundCanvas;

    setIsReady(true);
    canvasContainer.current.addEventListener("touchstart", handleMouseDown);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("touchend", handleMouseUp);
    canvasContainer.current.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (canvasContainer.current) {
        canvasContainer.current.removeEventListener(
          "mousedown",
          handleMouseDown
        );
        canvasContainer.current.removeEventListener(
          "touchstart",
          handleMouseDown
        );
        document.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchend", handleMouseUp);
      }
    };
  };

  return (
    <div className={style.canvasContainer} ref={canvasContainer}>
      <canvas
        ref={mainCanvas}
        width={dimensions().width}
        height={dimensions().height}
        style={{
          position: "absolute",
          opacity: 1,

          width: dimensions().width / scale + "px",
          height: dimensions().height / scale + "px",
        }}
      />
      <canvas
        ref={tempCanvas}
        width={dimensions().width}
        height={dimensions().height}
        style={{
          position: "absolute",
          opacity: 0,
          width: dimensions().width / scale + "px",
          height: dimensions().height / scale + "px",
        }}
      />
      <UndercoatCanvas
        dimensions={dimensions()}
        undercoatCanvas={undercoatCanvas}
        undercoatOverlayCanvas={undercoatOverlayCanvas}
        scale={scale}
        getUndercoat={getUndercoat}
      />
      <MiniCanvas
        miniCanvasRefs={miniCanvasRefs}
        dimensions={dimensions()}
        getCurrentStep={getCurrentStep()}
        getTapeWidth={calculatedTapeWidth}
        scale={scale}
      />
    </div>
  );
};
InteractiveCanvas.propTypes = {
  dimensions: PropTypes.func,
  selectedColor: PropTypes.string,
  resetCanvas: PropTypes.bool,
  setResetCanvas: PropTypes.func,
  setSnapCanvas: PropTypes.func,
  getSnapCanvas: PropTypes.any,
  endStep: PropTypes.func,
  step: PropTypes.object,
  selectedBrush: PropTypes.number,
  contextUndercoat: PropTypes.object,
  modalOpen: PropTypes.func,
  getSnapStepCanvas: PropTypes.any,
  setSnapStepCanvas: PropTypes.func,
  getCanvasNeedUndo: PropTypes.any,
  setCanvasNeedUndo: PropTypes.func,
  setSnapUndercoatCanvas: PropTypes.any,
  snapUndercoatCanvas: PropTypes.any,
  getSnapStepUndercoatCanvas: PropTypes.any,
  scale: PropTypes.number,
  setUndercoat: PropTypes.any,
  getUndercoat: PropTypes.func,
  setUnderCoatIsApply: PropTypes.any,
};

export default InteractiveCanvas;
