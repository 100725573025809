import React, { useContext, useState, useMemo } from "react";
import { MiniCanvasContext } from "Context/MiniCanvasProvider";

import { Container, Row, Col, Button } from "react-bootstrap";
import style from "./SelectedCanvas.module.scss";
import Modal from "../../Components/Modal";

const Index = () => {
  const langageJson = JSON.parse(localStorage.getItem("langageJson"));

  const { miniCanvasList, setSelectedMiniCanvas } =
    useContext(MiniCanvasContext);
  const [selectedCanvas, setSelectedCanvas] = useState("");
  const [showModal, setShowModal] = useState(true);

  const modalValues = () => {
    const content = (
      <>
        <h3 className={style.step5Subtitle}>{langageJson.cutContent1}</h3>
        <h3 className={style.step5Content}>
          {process.env.REACT_APP_DISPLAY !== "touchpad"
            ? langageJson.desktopCutContent2
            : langageJson.cutContent2}
        </h3>
      </>
    );

    return {
      content: content,
      showConfirm: true,
      showClose: true,
      className: "popinModalContent",
    };
  };

  const onSelectCanvasClick = (el) => {
    -setSelectedCanvas(el.className);
    setSelectedMiniCanvas(el);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  const creditModalHandleClose = () => {};

  const displayModal = useMemo(() => {
    return (
      <Modal
        handleShow={handleShow}
        handleClose={handleClose}
        showModal={showModal}
        modalValues={modalValues()}
      />
    );
  }, [showModal]);

  return (
    <Container>
      <Row>
        {miniCanvasList.map((el, key) => {
          return (
            <Col
              key={key}
              sm={4}
              style={{ padding: "0px", marginBottom: "10px" }}
            >
              <Button
                variant="outline-primary"
                className={style.minicanvasBtn}
                onClick={() => {
                  onSelectCanvasClick(el);
                }}
              >
                <img
                  className={
                    (el.className,
                    el.className === selectedCanvas
                      ? style.selectedMiniCanvas
                      : style.miniCanvas)
                  }
                  src={el.picture}
                />
              </Button>
            </Col>
          );
        })}
      </Row>
      {displayModal}
    </Container>
  );
};

export default Index;
