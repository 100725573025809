import PropTypes from "prop-types";
import React from "react";
import styles from "./CustomText.module.scss";

/**
 *
 * @param {{
 * styleSheet: "menuItem" | "fakeMenuItem" | "footerMenuItem" | "fakeFooterMenuItem" | "legalMenuItem" | "fakeLegalMenuItem" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "tags" | "baseText" | "baseLink";
 * }}
 *
 */
const CustomText = ({
  type = "p",
  children,
  styleSheet = "baseText",
  className,
  isHtml = false,
  ...rest
}) => {
  const params = {
    className: `${styles[styleSheet] || ""} ${className || ""}`,
    ...rest,
  };
  if (isHtml) params.dangerouslySetInnerHTML = { __html: children };
  return React.createElement(type, params, isHtml ? null : children);
};

CustomText.propTypes = {
  children: PropTypes.any,
  isHtml: PropTypes.bool,
  styleSheet: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};
export default CustomText;
