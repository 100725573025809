/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";

import BasicBtn from "../../Components/shared/BasicBtn";

import CustomText from "../../Components/shared/CustomText";
import styles from "./GDPRManager.module.scss";

import fr from "./lang/fr.json";
import en from "./lang/en.json";

const GDPRDetails = ({
  cookieState,
  setCookieState,
  handleValidation,
  color = "#CCC",
}) => {
  const selectedLangage = localStorage.getItem("selectedLangage")
    ? localStorage.getItem("selectedLangage") === "en"
      ? en
      : fr
    : null;
  const currentLang =
    selectedLangage || (navigator.language.includes("fr") ? fr : en);

  console.log(selectedLangage);

  return (
    <>
      <div className={styles.GDPRDetails}>
        <CustomText type="h3">{currentLang.GDPRDetails.title}</CustomText>
        <CustomText type="p">{currentLang.GDPRDetails.body}</CustomText>
        <hr />
        <CustomText type="h4">{currentLang.GDPRDetails.list.title}</CustomText>
        <div>
          <CustomText type="h5">
            {currentLang.GDPRDetails.list.statistics.title}
          </CustomText>
          <CustomText type="p">
            {currentLang.GDPRDetails.list.statistics.body}
          </CustomText>
          <ul className={styles.cookiesList}>
            <li>
              <CustomText type="span">Google Analytics</CustomText>
              <div className={styles.onoff}>
                <input
                  type="checkbox"
                  id="gtag"
                  checked={cookieState.gtag}
                  onChange={() =>
                    setCookieState({
                      ...cookieState,
                      gtag: !cookieState.gtag,
                    })
                  }
                />
                <label
                  className={
                    cookieState.gtag ? styles.btnSelected : styles.btnUnselected
                  }
                  htmlFor="gtag"
                />
              </div>
            </li>
          </ul>
        </div>
        <hr />
      </div>
      <div className={styles.GDPRButtons}>
        <BasicBtn showIcon={false} onClick={() => handleValidation()}>
          {currentLang.GDPRDetails.confirm}
        </BasicBtn>
      </div>
    </>
  );
};

GDPRDetails.propTypes = {
  cookieState: PropTypes.object,
  setCookieState: PropTypes.func,
  handleValidation: PropTypes.func,
  color: PropTypes.string,
};

export default GDPRDetails;
