import { GA_COOKIES, FBPIXEL_COOKIES } from "./references";

const pGaCookies = (removeCookie) => {
  const gaCookies = GA_COOKIES({});
  gaCookies.forEach((cookie) => removeCookie(cookie, { path: "/" }));
};

const pFbpCookies = (removeCookie) => {
  const fbpCookies = FBPIXEL_COOKIES();
  fbpCookies.forEach((cookie) => removeCookie(cookie, { path: "/" }));
};

export const purgeCookies = (cookieConsent, removeCookie, reload = true) => {
  if (cookieConsent?.gtag === false) {
    pGaCookies(removeCookie);
  }
  if (cookieConsent?.facebookpixel === false) {
    pFbpCookies(removeCookie);
  }
  if (cookieConsent?.youtube === false) {
    removeCookie("youtube", { path: "/" });
  }
  if (typeof window !== "undefined" && reload) window.location.reload(false);
};
