import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import CtaBtn from "../../Components/Btn/CtaBtn";
import logo from "../../pictures/logo.svg";
import { LocalisationContext } from "../../Context/LocalisationProvider";
import style from "./LangageChoice.module.scss";

const Home = () => {
  const pathName = useLocation().pathname;
  const localisation = useContext(LocalisationContext);

  // useEffect(() => {
  //   localStorage.removeItem("langage");
  //   localStorage.removeItem("selectedLangage");
  //   localStorage.removeItem("langageJson");
  // }, []);

  const setLangage = (selectedLangage) => {
    localisation.setSelectedLangage(selectedLangage);
  };

  return (
    <Container className={`${style.homeMainContainer}`}>
      <Row>
        <Col className={`${style.homeTitleContainer}`} xl={12}>
          <img className={`${style.logo}`} src={logo} alt="Logo" />
        </Col>
        <Col className={`${style.homeBtnContainer}`} xl={12}>
          <Link to={"/video"}>
            <CtaBtn
              label="FRANÇAIS"
              isActive={true}
              onClick={() => {
                setLangage("fr");
              }}
            />
          </Link>
          <Link to={"/video"}>
            <CtaBtn
              label="ENGLISH"
              onClick={() => {
                setLangage("en");
              }}
            />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
