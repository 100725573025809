import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { ScreenSaverContext } from "../../Context/ScreenSaverProvider";
import { GlobalScrollContext } from "../../Context/GlobalScrollProvider";
import ChangeOrientation from "../../Components/ChangeOrientation";
import VirtuaKeyboard from "../../Components/VirtualKeyboard";
import fr from "../../Utils/localisation/fr.json";

import Header from "../Header";
import SideMenu from "../SideMenu/";
import Footer from "../Footer";

import { ModalContext } from "../../Context/ModalProvider";

import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import style from "./Layout.module.scss";

const Layout = ({ children }) => {
  const currentLocation = useLocation().pathname;
  const selectedLangage = localStorage.getItem("selectedLangage");
  const langageJson = localStorage.getItem("langageJson")
    ? localStorage.getItem("langageJson")
    : fr;

  const { onMouseDown } = useContext(ScreenSaverContext);
  const { modalIsOpen } = useContext(ModalContext);
  const { displayScrollBar } = useContext(GlobalScrollContext);
  const display = process.env.REACT_APP_DISPLAY;
  const isMobileDevice = true;
  const { width } = window.screen;

  const navigate = useNavigate();
  const blurClassName =
    modalIsOpen && currentLocation === "/draw" ? style.blurBackground : "";

  const displayDarkBackground =
    currentLocation === "/select-langage"
      ? display === "touchpad"
        ? style.globalContainerHq
        : style.globalContainer
      : display === "touchpad"
      ? style.globalContainerDarkHq
      : style.globalContainerDark;

  useEffect(() => {
    if (
      currentLocation !== "/video" &&
      !currentLocation.includes("mobile") &&
      isMobileDevice &&
      width <= 900
    ) {
      navigate("/mobileHomepage");
    }
  }, [currentLocation]);

  useEffect(() => {
    if (!localStorage.getItem("langageJson")) {
      navigate("/");
    }
  }, []);

  return (
    <Container
      draggable="false"
      className={`${displayDarkBackground} ${blurClassName} ${
        displayScrollBar ? style.globalContainerScrollbar : ""
      }`}
      fluid
      onMouseDown={() => {
        onMouseDown();
      }}
    >
      <div
        draggable="false"
        style={{
          margin: "0 8%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Header isMobileDevice={isMobileDevice} />
        <Container className={style.mainContainer} fluid>
          <Row className={style.mainContainerRow}>
            <Col xl="3" md="12">
              {localStorage.getItem("langageJson") ||
              currentLocation === "/" ? (
                <SideMenu />
              ) : (
                ""
              )}
            </Col>
            <Col xl="9" sm="8" md={currentLocation === "/draw" ? "12" : ""}>
              {localStorage.getItem("langageJson") ||
              currentLocation === "/" ||
              currentLocation === "/video"
                ? children
                : ""}
            </Col>
          </Row>
        </Container>
        <Container fluid className={style.footerContainer}>
          {localStorage.getItem("langageJson") ||
          currentLocation === "/" ||
          currentLocation === "/video" ? (
            <Footer />
          ) : (
            ""
          )}
        </Container>
      </div>

      <VirtuaKeyboard />
      {currentLocation === "/draw" && <ChangeOrientation />}
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
