import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { createBrowserHistory, createHashHistory } from "history";
import StepperProvider from "./Context/StepperProvider";
import ModalProvider from "./Context/ModalProvider";
import EmailProvider from "./Context/EmailProvider";
import ScreenSaverProvider from "./Context/ScreenSaverProvider";

import UndercoatProvider from "./Context/UndercoatProvider";
import MiniCanvasProvider from "./Context/MiniCanvasProvider";
import LocalisationProvider from "./Context/LocalisationProvider";
import GlobalScrollProvider from "./Context/GlobalScrollProvider";
import RGPDProvider from "./Context/RGPDProvider";

import { isElectron } from "./Utils/utils";

import Home from "./Screens/Home";
import LangageChoice from "./Screens/LangageChoice";
import Video from "./Screens/Video";
import Intro from "Screens/Intro";
import Drawer from "./Screens/Drawer";
import Layout from "./Components/Layout";
import ScreenSaverVideo from "./Components/ScreenSaverVideo";
import SelectCanvas from "./Screens/SelectCanvas";
import SendCanvas from "./Screens/SendCanvas";
import EndScreen from "./Screens/EndScreen";
import MobileHomepage from "./Screens/MobileHomepage";
import MobileSocials from "./Screens/MobileSocials";
import NotFound from "./Screens/NotFound";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CookiesProvider } from "react-cookie";

import "bootstrap/dist/css/bootstrap.min.css";
import "styles/index.scss";

export const history = isElectron()
  ? createHashHistory()
  : createBrowserHistory();

const Router = isElectron() ? HashRouter : BrowserRouter;
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme();

root.render(
  <React.StrictMode>
    <Router basename="/" history={history}>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <RGPDProvider>
            <LocalisationProvider>
              <EmailProvider>
                <ModalProvider>
                  <StepperProvider>
                    <UndercoatProvider>
                      <MiniCanvasProvider>
                        <ScreenSaverProvider>
                          <GlobalScrollProvider>
                            <Routes>
                              <Route
                                path="*"
                                element={
                                  <Layout>
                                    <NotFound />
                                  </Layout>
                                }
                              />

                              <Route
                                exact
                                path="mobileHomepage"
                                element={
                                  <Layout>
                                    <MobileHomepage />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="mobileSocials"
                                element={
                                  <Layout>
                                    <MobileSocials />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/"
                                element={
                                  <Layout>
                                    <ScreenSaverVideo />
                                    <LangageChoice />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/video"
                                element={
                                  <Layout>
                                    <ScreenSaverVideo />
                                    <Video />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/intro"
                                element={
                                  <Layout>
                                    <ScreenSaverVideo />
                                    <Intro />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/play"
                                element={
                                  <Layout>
                                    <ScreenSaverVideo />
                                    <Home />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/draw"
                                element={
                                  <Layout props={{ test: "test" }}>
                                    <ScreenSaverVideo />
                                    <Drawer />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/select-canvas"
                                element={
                                  <Layout>
                                    <ScreenSaverVideo />
                                    <SelectCanvas />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/send-canvas"
                                element={
                                  <Layout>
                                    <ScreenSaverVideo />
                                    <SendCanvas />
                                  </Layout>
                                }
                              />
                              <Route
                                exact
                                path="/congratulation"
                                element={
                                  <Layout>
                                    <ScreenSaverVideo />
                                    <EndScreen />
                                  </Layout>
                                }
                              />
                            </Routes>
                          </GlobalScrollProvider>
                        </ScreenSaverProvider>
                      </MiniCanvasProvider>
                    </UndercoatProvider>
                  </StepperProvider>
                </ModalProvider>
              </EmailProvider>
            </LocalisationProvider>
          </RGPDProvider>
        </CookiesProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);
